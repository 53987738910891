import styled, { css } from 'styled-components';
import {
  black,
  dark,
  darkest,
  fontSize,
  firaFont,
  barlowFont,
  barlowFontHeavy,
  firaSpacing,
  barlowSpacing,
  accentPrimary,
} from '../constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import responsiveMixin from './responsiveMixin';

export const firaSans = (weight = 400) => css`
  font-family: ${firaFont};
  letter-spacing: ${firaSpacing};
  font-weight: ${weight};
`;

export const barlowSemiCondensed = (weight = 600) => css`
  font-family: ${barlowFont};
  letter-spacing: ${barlowSpacing};
  font-weight: ${weight};
`;

export const barlowCondensed = (weight = 800) => css`
  font-family: ${barlowFontHeavy};
  letter-spacing: ${barlowSpacing};
  font-weight: ${weight};
`;

// I'm not a huge fan of this anymore. I prefer to compose the styles I need starting
// from smaller variables now.
//
// For example, this call:
//
//  ${responsiveTypography('Barlow', 600, {
//    DEFAULT: fontSize['3.5x'],
//    DESKTOP: fontSize['3x'],
//    TABLET: fontSize['2.5x'],
//  })}
//
// May be rewritten as
//
//  ${barlowSemiCondensed()}
//  ${responsiveMixin({
//    DEFAULT: fontSize['3.5x'],
//    DESKTOP: fontSize['3x'],
//    TABLET: fontSize['2.5x'],
//  })}
export const responsiveTypography = (fontFamily, fontWeight, breakpoints) =>
  css`
    font-family: ${fontFamily == 'Barlow'
      ? fontWeight > 700
        ? barlowFontHeavy
        : barlowFont
      : firaFont};
    letter-spacing: ${fontFamily == 'Barlow'
      ? fontWeight > 700
        ? 'normal'
        : barlowSpacing
      : firaSpacing};
    color: ${fontFamily == 'Barlow' ? black : dark};
    font-weight: ${fontWeight};
    ${({ isCenteredMobile }) =>
      isCenteredMobile && MEDIA.TABLET`text-align: center;`}
    span {
      color: ${({ accentColor }) => accentColor || accentPrimary};
    }
    ${responsiveMixin(breakpoints)}
  `;

export const MainTitle = styled.h1`
  ${responsiveTypography('Barlow', 600, {
    DEFAULT: fontSize['3.5x'],
    DESKTOP: fontSize['3x'],
    TABLET: fontSize['2.5x'],
  })}

  margin-bottom: 32px;
`;

export const SecondaryTitle = styled.h2`
  ${responsiveTypography('Barlow', 600, {
    DEFAULT: fontSize['3x'],
    DESKTOP: fontSize['2.5x'],
    TABLET: fontSize['2.25x'],
  })}

  margin-bottom: 32px;
`;

export const LeadParagraph = styled.p`
  ${responsiveTypography('Fira', 600, {
    DEFAULT: fontSize['1.5x'],
    TABLET: fontSize['1.125x'],
  })}

  margin-bottom: 24px;
`;

export const MainParagraph = styled.p`
  ${responsiveTypography('Fira', 400, {
    DEFAULT: fontSize['1.125x'],
    TABLET: fontSize['1x'],
  })}
`;

export const Copy = styled.div`
  color: ${dark};
  text-align: left;
  p,
  ol,
  ul {
    ${fontSize['1.125x']}
    letter-spacing: ${firaSpacing};
    margin-bottom: 24px;
  }
  ol,
  ul {
    list-style: initial;
    padding-inline-start: 24px;
    li {
      margin-bottom: 16px;
      padding-left: 8px;
    }
  }
  ol {
    list-style: decimal;
  }
  h1,
  h2,
  h3 {
    ${responsiveTypography('Barlow', 600, {
      DEFAULT: fontSize['2.5x'],
      TABLET: fontSize['2.25x'],
    })}

    margin-bottom: 24px;
    ${MEDIA.TABLET`
      margin: 76px auto 32px;
    `}
  }
  h3 {
    color: ${darkest};
    ${fontSize['2x']}
  }
  strong,
  b {
    font-weight: 700;
    color: ${darkest};
  }
  img {
    box-shadow: 0px 8px 32px rgba(62, 62, 62, 0.1) !important;
    border-radius: 32px;
    margin: 0 auto 32px !important;
  }
`;
