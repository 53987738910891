import styled from 'styled-components';
import {
  sectionGap,
  backgroundSecondary,
  backgroundTertiary,
  sectionGapMobile,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const SectionWrapper = styled.section`
  position: relative;
  padding: ${({ hasNoPadding }) =>
    hasNoPadding ? `0 0 ${sectionGap}` : `${sectionGap} 0`};
  background-color: ${({ isDarker }) =>
    isDarker ? `${backgroundSecondary}` : 'transparent'};
  ${({ hasGradient }) => {
    if (hasGradient) {
      return `
        background: ${backgroundTertiary};
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 140px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
        }
      `;
    }
    return;
  }}

  ${MEDIA.TABLET`
    padding: ${({ hasNoPadding }) =>
      hasNoPadding ? `0 0 ${sectionGapMobile}` : `${sectionGapMobile} 0`};
 `};
`;
