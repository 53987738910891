import styled from 'styled-components';
import {
  accentPrimary,
  barlowFont,
  dark,
  darkest,
  lightest,
} from '../../constants/theme';

export const AccordionWrapper = styled.article`
  border-bottom: 1px solid ${lightest};
`;

export const AccordionHeading = styled.header`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionTitle = styled.h1`
  font: 400 24px/1.4 ${barlowFont};
  cursor: pointer;
  transition: color 300ms ease;
  color: ${({ isOpen }) => (isOpen ? accentPrimary : darkest)};
`;

export const AccordionToggle = styled.button`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-left: 48px;
  background: transparent;
  position: relative;
  cursor: pointer;
  &::before,
  &::after {
    --rotation: 0deg;
    content: '';
    position: absolute;
    border-radius: 1px;
    background: ${dark};
    top: 50%;
    left: 50%;
    transition: transform 300ms ease;
    transform: translate(-50%, -50%) rotate(var(--rotation));
  }
  &::before {
    width: 12px;
    height: 2px;
    ${({ isOpen }) => isOpen && '--rotation: 180deg;'};
  }
  &::after {
    width: 2px;
    height: 12px;
    ${({ isOpen }) => isOpen && '--rotation: 270deg;'};
  }
`;

export const AccordionContentWrapper = styled.section`
  transition: height 300ms ease-in-out 150ms;
  height: ${({ isOpen }) => (isOpen ? 'var(--height)' : '0')};
  overflow: hidden;
`;

export const AccordionContent = styled.section`
  padding: 8px 80px 24px 8px;
`;
