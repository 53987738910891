import React from 'react';
import { Link } from 'gatsby';
import Container from '../structure/container';
import {
  CorporateWrapper,
  ContentWrapper,
  FeaturesWrapper,
  CorporateTitle,
  CorporateParagraph,
  CardWrapper,
  Icon,
  CardContent,
  ParagraphSmall,
  FormWrapper,
  FormTitle,
  Form,
} from './layout.css';
import IconWeek from '../../images/icons/icon-week.svg';
import IconShoe from '../../images/icons/icon-shoe.svg';
import IconVideo from '../../images/icons/icon-video.svg';
import IconGoals from '../../images/icons/icon-goals.svg';

const CorporateLayout = () => (
  <Container>
    <CorporateWrapper>
      <ContentWrapper>
        <CorporateTitle>
          <span>Corporate fitness programs</span> are crucial for the well-being
          of your employees.
        </CorporateTitle>
        <CorporateParagraph>
          What if we told you we’re working to provide your company with a
          dedicated subscription plan?
        </CorporateParagraph>
        <FeaturesWrapper>
          <CardWrapper>
            <Icon>
              <IconVideo />
            </Icon>
            <CardContent>
              More than 400 video-guided personalized workouts
            </CardContent>
          </CardWrapper>
          <CardWrapper>
            <Icon>
              <IconWeek />
            </Icon>
            <CardContent>
              Choose your problem areas and see great results in 30 days
            </CardContent>
          </CardWrapper>
          <CardWrapper>
            <Icon>
              <IconGoals />
            </Icon>
            <CardContent>
              You set goals and preferences, we create your own fitness plan
            </CardContent>
          </CardWrapper>
          <CardWrapper>
            <Icon>
              <IconShoe />
            </Icon>
            <CardContent>
              Discover health tips, get motivated, and be confident
            </CardContent>
          </CardWrapper>
        </FeaturesWrapper>
      </ContentWrapper>
      <FormWrapper>
        <FormTitle>Leave your email to find out more!</FormTitle>
        <Form id="mc_embed_signup">
          <form
            action="https://bendingspoons.us19.list-manage.com/subscribe/post?u=bae10f6654adefb9d9a9258a8&amp;id=a9b9977bc8"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email*</label>
                <input
                  placeholder="john.doe@gmail.com"
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">Name*</label>
                <input
                  placeholder="John Doe"
                  type="text"
                  name="FNAME"
                  className="required"
                  id="mce-FNAME"
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-CNAME">Company Name*</label>
                <input
                  placeholder="Bending Spoons"
                  type="text"
                  name="CNAME"
                  className="required"
                  id="mce-CNAME"
                />
              </div>
              <div className="mc-field-group size1of2">
                <label htmlFor="mce-MMERGE3">N. of Employees</label>
                <input
                  placeholder="150"
                  type="number"
                  name="MMERGE3"
                  className=""
                  id="mce-MMERGE3"
                />
              </div>
              <div className="gdpr">
                <ParagraphSmall>
                  Have a look at our{' '}
                  <Link to="/privacy-policy/">Privacy Notice</Link> to know how
                  we will process your data.
                </ParagraphSmall>
              </div>
              {/* <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style="display:none"
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style="display:none"
                ></div>
              </div>
              <div
                style="position: absolute; left: -5000px;"
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_bae10f6654adefb9d9a9258a8_a9b9977bc8"
                  tabIndex="-1"
                  value=""
                />
              </div> */}
              <div className="clear">
                <input
                  type="submit"
                  value="Send Request"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
            </div>
          </form>
        </Form>
      </FormWrapper>
    </CorporateWrapper>
  </Container>
);

export default CorporateLayout;
