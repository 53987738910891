import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import PropTypes from 'prop-types';

import ErrorModal from '../ui/components/errorModal';
import LoginForm from './loginForm';

import { LoadingWrapper } from './login.css';
import {
  StepSubtitle,
  StepTextWrapper,
  StepTitle,
} from '../flow/steps/types/shared.css';

const Login = () => {
  const [error, setError] = useState('');
  const [status, setStatus] = useState('unsent');

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          oracle {
            url
            website
          }
        }
      }
    }
  `);

  const { url, website } = site.siteMetadata.oracle;

  const login = async (email) => {
    try {
      setStatus('loading');
      const response = await fetch(`${url}/stripe/portal_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, website }),
      });
      const data = await response.json();
      // eslint-disable-next-line no-console
      console.log(data);
      setStatus('success');
    } catch (e) {
      setStatus('error');
      setError(e.message);
    }
  };

  const onSubmit = (email) => {
    login(email);
  };

  switch (status) {
    case 'success':
      return (
        <StepTextWrapper style={{ textAlign: 'center' }}>
          <StepTitle>
            Magic link sent{' '}
            <span role="img" aria-label="Magic wand emoji">
              🪄
            </span>
          </StepTitle>
          <StepSubtitle>
            If your mail address is linked to a valid account, you&apos;ll
            shortly receive an email from us. <br /> Check your inbox!
          </StepSubtitle>
        </StepTextWrapper>
      );
    case 'error':
    case 'unsent':
    case 'loading':
    default:
      return (
        <>
          <LoadingWrapper isLoading={status == 'loading'}>
            <LoginForm readOnly={status != 'unsent'} onSubmit={onSubmit} />
          </LoadingWrapper>
          {status == 'error' && (
            <ErrorModal message={error} onClose={() => setStatus('unsent')} />
          )}
        </>
      );
  }
};

// Login.propTypes = {};

export default Login;
