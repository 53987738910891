import styled from 'styled-components';
import {
  containerWidth,
  containerSmallWidth,
  containerMin,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const ContainerWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: ${({ isFlex }) => (isFlex ? 'flex' : '')};
  ${MEDIA.MIN_DESKTOP`
    max-width: ${containerMin};
  `};
  ${MEDIA.MIN_WIDESCREEN`
    max-width: ${({ isSmall }) =>
      isSmall ? `${containerSmallWidth}` : `${containerWidth}`};
  `};
  ${MEDIA.DESKTOP`
    padding: 0 40px;
 `};
  ${MEDIA.TABLET`
    padding: 0 20px;
 `};
`;
