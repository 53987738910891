import React from 'react';
import PropTypes from 'prop-types';
import { ContainerWrapper } from './container.css';

const Container = ({ children, isSmall, isFlex }) => (
  <ContainerWrapper isSmall={isSmall} isFlex={isFlex}>
    {children}
  </ContainerWrapper>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  isSmall: PropTypes.bool,
  isFlex: PropTypes.bool,
};

export default Container;
