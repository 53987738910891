import React from 'react';
import { Link } from 'gatsby';
import { FooterParagraph } from './shared.css';

const Imprint = () => (
  <FooterParagraph>
    Copyright © Bending Spoons Operations S.p.A. | Via Nino Bonnet 10, 20154, Milan, Italy | VAT, tax code, and number of registration with the Milan Monza Brianza Lodi Company Register 13368510965 | REA number MI 2718456 | Contributed capital €150,000.00 fully paid-in | Sole shareholder company subject to the management and coordination of Bending Spoons S.p.A.
    <br />
    <br />
    <Link to="/support/">Help Center</Link> -{' '}
    <a href="https://bendingspoons.com/tos.html?app=1099771240" target="blank">
      Terms & Conditions
    </a>{' '}
    -{' '}
    <a href="/privacy-policy" target="blank">
      Website Privacy and Cookie Policy
    </a>
  </FooterParagraph>
);

export default Imprint;
