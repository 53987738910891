import styled from 'styled-components';
import {
  backgroundPrimary,
  barlowFont,
  barlowSpacing,
  firaFont,
  firaSpacing,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const TileWrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 16px;
  background: linear-gradient(117.98deg, #3544a4 -21.97%, #3f81ef 91.26%);
  box-shadow: 0px 4px 32px rgba(63, 129, 239, 0.3);
  padding: 48px 80px;
  ${MEDIA.TABLET`
    padding: 24px;
    max-width: 335px;
    margin: 0 auto;
 `};
`;

export const TileImg = styled.img`
  position: absolute;
  right: 40px;
  bottom: 0;
  max-width: 480px;
  ${MEDIA.WIDESCREEN`
    right: 24px;
  `}
  ${MEDIA.DESKTOP`
    display: none;
  `}
`;

export const ContentWrapper = styled.div`
  width: 50%;
  ${MEDIA.TABLET`
    width: 100%;
 `};
`;

export const TileTitle = styled.div`
  font-family: ${barlowFont};
  color: #ffffff;
  font-weight: 600;
  font-size: 48px;
  line-height: 51px;
  letter-spacing: ${barlowSpacing};
  margin-bottom: 24px;
  ${MEDIA.TABLET`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
 `};
`;

export const TileParagraph = styled.div`
  font-family: ${firaFont};
  font-size: 20px;
  line-height: 31px;
  letter-spacing: ${firaSpacing};
  padding-bottom: 16px;
  color: rgba(252, 252, 252, 0.8);
  ${MEDIA.TABLET`
    font-size: 16px;
    line-height: 25px;
  `}
`;

export const TileSubParagraph = styled.div`
  font-family: ${barlowFont};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.807692px;
  text-transform: uppercase;
  color: ${backgroundPrimary};
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  ${MEDIA.TABLET`
    justify-content: space-between;
    svg, a {
      height: 40px;
      width: 135px;
    }
  `}
`;
