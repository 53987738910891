import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { LinkWrapper } from './links.css';
import { Link } from 'gatsby';

const HeaderLinks = ({ links }) => {
  const location = useLocation();
  return (
    <>
      {links.map((link) => (
        <LinkWrapper
          key={link.path}
          isActive={location.pathname.includes(link.path)}
        >
          <Link to={link.path}>{link.label}</Link>
        </LinkWrapper>
      ))}
    </>
  );
};

HeaderLinks.propTypes = {
  links: PropTypes.array.isRequired,
};

export default HeaderLinks;
