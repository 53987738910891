import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  Title,
  Copy,
  CardWrapper,
  ContentWrapper,
  ImageWrapper,
} from './card.css';
import CategoryBadge from './category/badge';

const BlogCard = ({ isFeatured, frontmatter, excerpt }) => {
  const url = `/${frontmatter.categories.toLowerCase()}/${frontmatter.slug}/`;
  return (
    <CardWrapper isFeatured={isFeatured}>
      <ImageWrapper to={url}>
        <Img
          fluid={frontmatter.coverImage.childImageSharp.fluid}
          alt={frontmatter.title}
        />
      </ImageWrapper>
      <ContentWrapper>
        <CategoryBadge category={frontmatter.categories} />
        <Title to={url}>{frontmatter.title}</Title>
        <Copy>{frontmatter.description || excerpt}</Copy>
      </ContentWrapper>
    </CardWrapper>
  );
};

BlogCard.propTypes = {
  isFeatured: PropTypes.bool,
  frontmatter: PropTypes.object,
  excerpt: PropTypes.string,
};

export default BlogCard;

export const query = graphql`
  fragment BlogCard on MarkdownRemark {
    excerpt
    frontmatter {
      date
      title
      description
      firstHeading
      slug
      categories
      coverImage {
        childImageSharp {
          fluid(maxWidth: 480, quality: 90, srcSetBreakpoints: [200, 320]) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      coverImageAlt
    }
  }
`;
