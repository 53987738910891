import React from 'react';
import { SocialWrapper } from './social.css';
import { FooterParagraph } from './shared.css';
import Instagram from '../../../images/social/instagram.svg';
import Facebook from '../../../images/social/facebook.svg';
import TikTok from '../../../images/social/tiktok.svg';
import Pinterest from '../../../images/social/pinterest.svg';

const Social = () => (
  <>
    <FooterParagraph>Follow us on social media:</FooterParagraph>
    <SocialWrapper>
      <a href="https://www.facebook.com/30dayfitness/" target="blank">
        <Facebook />
      </a>
      <a href="https://www.instagram.com/30dayfitness/" target="blank">
        <Instagram />
      </a>
      <a href="https://www.tiktok.com/@30dayfitnessapp/" target="blank">
        <TikTok />
      </a>
      <a href="https://www.pinterest.it/30dayfitnessapp/" target="blank">
        <Pinterest />
      </a>
    </SocialWrapper>
  </>
);

export default Social;
