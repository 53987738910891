import styled from 'styled-components';
import {
  dark,
  darkest,
  accentPrimary,
  fontSize,
} from '../../../constants/theme';
import { responsiveTypography, Copy } from '../../../styles/typography.css';
import { primaryButton } from '../../../styles/buttons.css';
import MEDIA from '../../../helpers/mediaTemplates';

export const MoreTitle = styled.h1`
  ${responsiveTypography('Barlow', 600, {
    DEFAULT: fontSize['3x'],
    DESKTOP: fontSize['2x'],
  })}
  text-align: center;

  margin-bottom: 32px;
`;

export const MoreTitleHeavy = styled.h1`
  ${responsiveTypography('Barlow', 800, {
    DEFAULT: fontSize['3.5x'],
    DESKTOP: fontSize['2.5x'],
  })}
  text-transform: uppercase;
  text-align: center;

  margin-bottom: 16px;
`;

export const MoreSubtitle = styled.h3`
  ${responsiveTypography('Fira', 400, {
    DEFAULT: fontSize['1.25x'],
  })}
  text-align: center;

  margin-bottom: 48px;
  ${MEDIA.DESKTOP`
    margin-bottom: 24px;
  `}
`;

export const MoreImageWrapper = styled.div`
  margin-bottom: 64px;
  border-radius: 40px;
  overflow: hidden;
  ${MEDIA.DESKTOP`
    border-radius: 0;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 32px;
  `}
  ${MEDIA.SMALL_PHONE`
    margin-left: -8px;
    margin-right: -8px;
  `}
`;

export const MoreCopy = styled(Copy)`
  text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
  h2,
  h3 {
    color: ${darkest};
    margin: 0 0 16px;
  }
  h2 {
    ${fontSize['2x']}
  }
  h3 {
    ${fontSize['1.5x']}
  }
  p {
    margin-bottom: 16px;
  }
  ul,
  ol {
    padding-left: 16px;
    margin-left: 20px;
    margin-bottom: 0;
  }
  b {
    color: ${dark};
  }
  span {
    color: ${({ accentColor }) => accentColor || accentPrimary};
    font-weight: 700;
  }
`;

export const MoreCopyButton = styled.a`
  ${primaryButton({ size: 'xl', pill: true })}
  margin: 32px auto 40px;
`;
