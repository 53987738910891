import React from 'react';
import FreeTrialLayout from 'components/landings/freeTrial';

const freeTrial = () => (
  <FreeTrialLayout
    heading="get your offer"
    subheading="30 Day Fitness subscription"
    closing="your discount"
  />
);

export default freeTrial;
