import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, VeggieBurger, DownloadButton } from './mobile.css';
import useAdjustLink from '../../../hooks/use-adjust-link';

const HeaderMobile = ({ setIsOpen }) => {
  const ctaRef = useRef();
  const url = useAdjustLink({ campaign: 'navbar', store: 'apple' }, ctaRef);
  return (
    <Container>
      <DownloadButton href={url} ref={ctaRef}>
        Install now
      </DownloadButton>
      <VeggieBurger onClick={() => setIsOpen(true)} />
    </Container>
  );
};

HeaderMobile.propTypes = {
  setIsOpen: PropTypes.func,
};

export default HeaderMobile;
