import React, { useEffect, useState } from 'react';

import Head from '../components/common/head';
import Flow from '../components/monetization/flow/flow';
import defaultFlow from '../components/monetization/flow/defaultFlow.json';
import useOptimize from '../hooks/use-optimize';
import merge from '../components/monetization/utils/merge';
import styled from 'styled-components';
import { parse } from 'query-string';
import { graphql, useStaticQuery } from 'gatsby';

const FullHeightFlow = styled(Flow)`
  height: 100vh;
  width: ${({ mode }) => (mode != 'live' ? '50%' : '100%')};
  /* Avoid Chrome to see Safari hack */
  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
`;

const DebugPanel = styled.div`
  width: 50%;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  z-index: 1;
  background: whitesmoke;
`;

const GetStarted = ({ location }) => {
  const getKey = (flow) => `${flow.experiment}:${flow.segment}:${flow.version}`;

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          product {
            priceId
          }
        }
      }
    }
  `);
  const priceId = site.siteMetadata.product.priceId;
  if (priceId) {
    // hot fix with env var
    for (let step of defaultFlow.steps) {
      if (step.name.toLowerCase() == "payment") {
        step.props.priceId = priceId;
      }
    }
  }

  const [flow, setFlow] = useState(defaultFlow);
  const [value, setValue] = useState();

  const queryParams = parse(location.search);

  useEffect(() => {
    if (queryParams.tag) {
      try {
        // ALWAYS update the delta to the latest diffBase (defaultFlow) so as to have a matching starting point
        const delta = require(`../components/monetization/flow/flowDeltas/${process.env.GATSBY_ENV}/${queryParams.tag}.json`);
        // failsafe in case we're trying to apply an older delta to a newer flow
        const baseFlowKey = getKey(flow);
        if (delta.diffBase == baseFlowKey) {
          setFlow({
            ...delta,
            steps: merge(flow.steps, delta.operations),
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  useOptimize(() => {
    if (queryParams.tag) return;
    const customFlow = window.customFlow;
    if (customFlow) {
      const customFlowKey = getKey(customFlow);
      const baseFlowKey = getKey(flow);
      if (customFlowKey != baseFlowKey && customFlow.diffBase == baseFlowKey) {
        // It should run once and then never again
        setFlow({
          ...window.customFlow,
          steps: merge(flow.steps, window.customFlow.operations),
        });
      }
    }
  });

  const mode = flow.modeOverride || 'live';

  return (
    <>
      <Head pageTitle="Let's Start Your Journey">
        <meta name="robots" value="noindex" />
      </Head>
      <div style={{ display: 'flex' }}>
        <FullHeightFlow
          flow={flow}
          mode={mode}
          initialStep={mode != 'live' ? 0 : undefined}
          onChange={mode != 'live' ? setValue : undefined}
        />
        {mode != 'live' && (
          <DebugPanel>{JSON.stringify(value, null, 2)}</DebugPanel>
        )}
      </div>
    </>
  );
};

GetStarted.propTypes = {};

export default GetStarted;
