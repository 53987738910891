import styled, { css } from 'styled-components';
import MEDIA from '../../../helpers/mediaTemplates';
import responsiveMixin from '../../../styles/responsiveMixin';
import { Input, inputBorderMixin } from './input.css';

export const InputGroupIcon = styled.div`
  ${inputBorderMixin}
  ${responsiveMixin({
    DEFAULT: 'padding-left: 24px;',
    TABLET: 'padding-left: 16px;',
    PHONE: 'padding-left: 8px;',
  })}
  /* dark, 25% */
  color: #d2d5de;
  display: flex;
  align-items: center;

  ${({ hasError }) =>
    hasError &&
    css`
      color: #f38c91;
    `}
`;

export const InputGroup = styled.div`
  display: flex;
  position: relative;
  border-radius: 16px;

  ${Input}:focus {
    outline: 0;
  }

  ${MEDIA.SMALL_PHONE`
    ${InputGroupIcon} {
      display: none;
    }
  `}

  ${({ withIcon }) =>
    withIcon &&
    MEDIA.MIN_SMALL_PHONE`
      ${InputGroupIcon} {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0;
      }
      ${Input} {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0;

        padding-left: 12px;
      }
    `}
`;
