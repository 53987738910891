import React from 'react';
import { Helmet } from 'react-helmet';

import { ExperienceManager } from '@bendingspoons/experience-manager';
import Flow from '../components/monetization/flow/flow';

import { stepDefs } from '../components/monetization/flow/steps/types';
import defaultFlow from '../components/monetization/flow/defaultFlow.json';

const ExperienceManagerPage = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" value="noindex" />
      </Helmet>
      <ExperienceManager
        flowComponent={Flow}
        initialFlow={defaultFlow}
        stepDefs={stepDefs}
        metaDef={{
          progressBar: { type: 'bool', label: 'Show Progress Bar' },
          tag: { type: 'string', label: 'Step Tag' },
          hideButtons: { type: 'bool', label: 'Hide Buttons' },
          hideBackButton: { type: 'bool', label: 'Hide Back Button' },
          className: { type: 'string', label: 'Submit Button Class Name' },
          enterActionKind: {
            type: 'string',
            label: 'Enter Action Kind (Adjust)',
          },
          exitActionKind: {
            type: 'string',
            label: 'Exit Action Kind (Adjust)',
          },
          submitActionKind: {
            type: 'string',
            label: 'Submit Action Kind (Adjust)',
          },
          vAlign: {
            type: 'select',
            label: 'Vertical Aligment',
            options: ['top', 'center', 'bottom'],
          },
          textAlign: {
            type: 'select',
            label: 'Text Aligment',
            options: ['center', 'left', 'right'],
          },
          cardBackground: {
            type: 'select',
            label: 'Card Background',
            options: ['opaque', 'transparent'],
          },
          invertCardText: { type: 'bool', label: 'Invert Card Text' },
          backgroundImage: { type: 'image', label: 'Background Image' },
        }}
        mediaGallery={[
          '/onboarding/background@2x.png 2x, /onboarding/background.png',
          '/onboarding/crunches@2x.png 2x, /onboarding/crunches.png',
          '/onboarding/movement@2x.png 2x, /onboarding/movement.png',
          '/onboarding/smile@2x.png 2x, /onboarding/smile.png',
          '/onboarding/before-after@2x.png 2x, /onboarding/before-after.png',
          '/onboarding/before-after-man@2x.png 2x, /onboarding/before-after-man.png',
          '/onboarding/oh-no@2x.png 2x, /onboarding/oh-no.png',
          '/onboarding/meal-plan@2x.png 2x, /onboarding/meal-plan.png',
          '/onboarding/well-done@2x.png 2x, /onboarding/well-done.png',
        ]}
      />
    </>
  );
};

export default ExperienceManagerPage;
