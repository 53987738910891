import styled from 'styled-components';

export const ViewportBox = styled.div`
  width: 100%;
  height: 100vh;

  padding: 32px 16px;
`;

export const VerticalStack = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Frame = styled.div`
  flex-grow: 1;
  width: 100%;

  display: flex;
  > * {
    margin: auto;
  }
`;

export const SmallContainer = styled.div`
  width: 100%;
  max-width: 480px;
`;
