import React from 'react';
// import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import Head from '../../common/head';

import Login from './login';

import {
  ViewportBox,
  VerticalStack,
  Frame,
  SmallContainer,
} from './template.css';

const LoginTemplate = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo-main.png" }) {
        childImageSharp {
          fixed(width: 240, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <ViewportBox>
      <Head pageTitle="Manage Subscription" />

      <VerticalStack>
        <GatsbyImage
          fixed={logo.childImageSharp.fixed}
          alt="30 Day Fitness logo"
        />

        <Frame>
          <SmallContainer>
            <Login />
          </SmallContainer>
        </Frame>
      </VerticalStack>
    </ViewportBox>
  );
};

// LoginTemplate.propTypes = {};

export default LoginTemplate;
