import styled from 'styled-components';
import {
  backgroundPrimary,
  darkest,
  dark,
  medium,
  firaFont,
} from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const CardWrapper = styled.div`
  background: ${backgroundPrimary};
  box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
  padding: 24px 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Copy = styled.p`
  font-family: ${firaFont};
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: ${dark};
  ${MEDIA.TABLET`
    text-align: center;
 `};
`;

export const MetaWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  border-top: 1px solid #ececec;
  padding-top: 16px;
  ${MEDIA.TABLET`
    justify-content: center;
 `};
`;

export const Name = styled.span`
  position: relative;
  font-family: ${firaFont};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${darkest};
  margin-right: 16px;
  padding-right: 4px;
  &::after {
    content: '';
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-2px);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: ${medium};
  }
`;

export const Date = styled.span`
  font-family: ${firaFont};
  font-style: normal;
  font-weight: 500;
  margin: auto 16px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${medium};
`;
