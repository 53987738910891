import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import {
  TeaserTitle,
  TeaserLeadParagraph,
  TeaserMainParagraph,
} from '../category/teaser.css';

const TagTeaser = ({ page }) => (
  <>
    <TeaserTitle isCenteredMobile>{page.title}</TeaserTitle>
    <TeaserLeadParagraph isCenteredMobile>{page.lead}</TeaserLeadParagraph>
    <TeaserMainParagraph isCenteredMobile>{page.main}</TeaserMainParagraph>
  </>
);

TagTeaser.propTypes = {
  name: PropTypes.string.isRequired,
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    main: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment TagTeaser on MarkdownRemark {
    frontmatter {
      name
      category
      slug
      page {
        title
        lead
        main
      }
    }
  }
`;

export default TagTeaser;
