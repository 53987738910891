import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import TagBadge from '../tag/badge';
import {
  TeaserTitle,
  TeaserLeadParagraph,
  TeaserMainParagraph,
  TeaserTagsHeading,
  TeaserTagsWrapper,
} from './teaser.css';

const CategoryTeaser = ({ name, color, page, tags = [] }) => (
  <>
    <TeaserTitle isCenteredMobile accentColor={color}>
      <span>{name}: </span> {page.title}
    </TeaserTitle>
    <TeaserLeadParagraph isCenteredMobile>{page.lead}</TeaserLeadParagraph>
    <TeaserMainParagraph isCenteredMobile>{page.main}</TeaserMainParagraph>
    {!!tags.length && (
      <>
        <TeaserTagsHeading>Related Tags</TeaserTagsHeading>
        <TeaserTagsWrapper>
          {tags.map((tag) => (
            <TagBadge tag={tag} key={tag} />
          ))}
        </TeaserTagsWrapper>
      </>
    )}
  </>
);

CategoryTeaser.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    main: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment CategoryTeaser on MarkdownRemark {
    frontmatter {
      name
      slug
      color
      page {
        title
        lead
        main
      }
    }
  }
`;

export default CategoryTeaser;
