import styled from 'styled-components';
import { black, medium } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const TestimonialWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 640px;
  background: white;
  box-shadow: 0px 3.6px 60px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  padding: 24px;
  display: flex;
  align-items: center;
  ${MEDIA.TABLET`
    flex-direction: column;
  `}
`;

export const TestimonialImage = styled.div`
  width: 100%;
  max-width: 480px;
  border-radius: 20px;
  overflow: hidden;
`;

export const TestimonialContent = styled.div`
  max-width: 320px;
  margin-left: 32px;
  ${MEDIA.TABLET`
    margin-left: 0;
    margin-top: 24px;
  `}
`;

export const TestimonialContentQuote = styled.p`
  color: ${black};
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
`;

export const TestimonialContentName = styled.h4`
  font-size: 16px;
  line-height: 26px;
  color: ${medium};
`;
