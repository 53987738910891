import styled from 'styled-components';
import { Link } from 'gatsby';
import { primaryButton } from '../../styles/buttons.css';
import { SecondaryTitle, MainParagraph } from '../../styles/typography.css';

export const BlogContent = styled.div`
  max-width: 540px;
  margin: auto;
  text-align: center;
`;

export const BlogTitle = styled(SecondaryTitle)``;

export const BlogParagraph = styled(MainParagraph)`
  margin-bottom: 32px;
`;

export const BlogButton = styled(Link)`
  ${primaryButton()}
`;

export const BlogButtonWrapper = styled.div`
  margin-bottom: 64px;
`;
