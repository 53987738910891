import styled from 'styled-components';
import { darkest, firaFont, firaSpacing } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const FooterParagraph = styled.div`
  font-family: ${firaFont};
  text-align: inherit;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: ${firaSpacing};
  color: #a5a7b7;
  ${MEDIA.TABLET`
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 25px;
    padding: 0 20px;
  `}
  a {
    color: ${darkest};
    text-decoration: underline;
  }
`;
