import styled from 'styled-components';
import { darkest, accentPrimary, white } from '../../../../constants/theme';
import {
  BaseButton,
  buttonBlock,
  buttonContent,
} from '../../../../styles/buttons.css';
import {
  MainParagraph,
  SecondaryTitle,
} from '../../../../styles/typography.css';

export const Paragraph = styled(MainParagraph)`
  margin-bottom: 16px;
`;

export const Title = styled(SecondaryTitle)`
  margin-bottom: 24px;
  color: ${darkest};
`;

export const Button = styled(BaseButton)`
  margin-top: 24px;
  background-color: ${accentPrimary};
  color: ${white};
  font-weight: 600;
  ${buttonBlock()}
  ${buttonContent({})}
`;
