import React from 'react';
import Layout from '../components/layout/layout';
import Head from '../components/common/head';
import Container from '../components/structure/container';

const NotFound = () => (
  <Layout>
    <Head />
    <Container>Not found.</Container>
  </Layout>
);

export default NotFound;
