import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import {
  TestimonialWrapper,
  TestimonialImage,
  TestimonialContent,
  TestimonialContentQuote,
  TestimonialContentName,
} from './testimonial.css';

const Testimonial = () => (
  <TestimonialWrapper>
    <TestimonialImage>
      <StaticQuery
        query={graphql`
          {
            file(relativePath: { eq: "more/nadia.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 480, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={({ file }) => (
          <GatsbyImage fluid={file.childImageSharp.fluid} alt="Nadia" />
        )}
      />
    </TestimonialImage>
    <TestimonialContent>
      <TestimonialContentQuote>
        &quot;With 30 Day Fitness, I was able to get in shape quickly and easily
        by training whenever I had time. I highly recommend it!&quot;
      </TestimonialContentQuote>
      <TestimonialContentName>
        <b>Nadia</b>, who got fit with 30 Day Fitness
      </TestimonialContentName>
    </TestimonialContent>
  </TestimonialWrapper>
);

export default Testimonial;
