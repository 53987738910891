import { useStaticQuery, graphql } from 'gatsby';

const useCategory = (slug) => {
  const { allCategories } = useStaticQuery(graphql`
    {
      allCategories: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(categories)/" } }
      ) {
        edges {
          node {
            frontmatter {
              color
              name
              slug
            }
          }
        }
      }
    }
  `);
  const category = allCategories.edges.find(
    (edge) => edge.node.frontmatter.slug === slug
  ).node.frontmatter;

  category.url = `/${category.slug.toLowerCase()}/`;

  return category;
};

export default useCategory;
