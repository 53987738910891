import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Container from 'components/structure/container';
import Section from 'components/structure/section';
import {
  BlogContent,
  BlogTitle,
  BlogParagraph,
  BlogButton,
  BlogButtonWrapper,
} from './archive.css';
import ArticlesList from './articlesList';

const POST_ARCHIVE_QUERY = graphql`
  query BlogPostQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      limit: 3
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
  }
`;

const BlogArchive = () => (
  <StaticQuery
    query={POST_ARCHIVE_QUERY}
    render={({ allMarkdownRemark }) => (
      <Section isDarker>
        <Container>
          <BlogContent>
            <BlogTitle isCenteredMobile>
              30 Days and <span>BEYOND</span>
            </BlogTitle>
            <BlogParagraph isCenteredMobile>
              30 Day Fitness is not just an app, <b>it’s a lifestyle</b>. Read
              our blog to discover tips on fitness, nutrition and well-being.
              Train better, feel better, live better!
            </BlogParagraph>

            <BlogButtonWrapper>
              <BlogButton to="/beyond/">Browse all Articles</BlogButton>
            </BlogButtonWrapper>
          </BlogContent>

          <ArticlesList articles={allMarkdownRemark.edges} />
        </Container>
      </Section>
    )}
  />
);

export default BlogArchive;
