import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { SocialWrapper, ShareButton } from './shareButtons.css';

const ShareButtons = ({ title, url, tags }) => {
  return (
    <SocialWrapper>
      <FacebookShareButton url={url}>
        <ShareButton className="facebook">Facebook</ShareButton>
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} hashtags={tags}>
        <ShareButton className="twitter">Twitter</ShareButton>
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <ShareButton className="whatsapp">WhatsApp</ShareButton>
      </WhatsappShareButton>
    </SocialWrapper>
  );
};

ShareButtons.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  tags: PropTypes.string,
};

export default ShareButtons;
