import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Container from '../structure/container';
import {
  HeroWrapper,
  HeroContent,
  HeroTitle,
  HeroParagraph,
  HeroArtwork,
  ArtworkWrapper,
  BarWrapper,
  AppOfTheDay,
  ButtonWrapper,
  HighilightWrapper,
  HighilightText,
} from './hero.css';
import * as appOfTheDay from '../../images/appoftheday.png';
import StoreButtonApple from '../common/store-button/apple';
import StoreButtonGoogle from '../common/store-button/google';

const Hero = ({ title, paragraph, image }) => (
  <Container>
    <HeroWrapper>
      <HeroContent>
        <HeroTitle
          isCenteredMobile
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <HeroParagraph isCenteredMobile>{paragraph}</HeroParagraph>
      </HeroContent>
      <HeroArtwork>
        <ArtworkWrapper>
          <Img fluid={image} />
        </ArtworkWrapper>
      </HeroArtwork>
      <BarWrapper>
        <AppOfTheDay>
          <img src={appOfTheDay} alt="app of the day" />
        </AppOfTheDay>
        <ButtonWrapper>
          <StoreButtonApple campaign="badge-home-top" hasMargin />
          <StoreButtonGoogle campaign="google-badge-home-top" />
        </ButtonWrapper>
        <HighilightWrapper>
          <HighilightText>Free trial available for new members</HighilightText>
        </HighilightWrapper>
      </BarWrapper>
    </HeroWrapper>
  </Container>
);

Hero.propTypes = {
  title: PropTypes.node.isRequired,
  paragraph: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
};

export default Hero;
