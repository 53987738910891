import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';
import { blogWidth, medium } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { primaryButton } from '../../styles/buttons.css';

export const MoreSection = styled.section`
  padding: ${({ isBordered }) => (isBordered ? '32px 0' : '48px 0')};
  ${({ hasHero }) => hasHero && 'padding-top: 0;'}

  ${({ isBordered }) =>
    isBordered &&
    css`
      border-bottom: 1px solid rgba(62, 134, 145, 0.2);
    `}
`;

export const MoreContainer = styled.div`
  max-width: ${({ isNarrow }) => (isNarrow ? blogWidth : '782px')};
  margin: 0 auto;
  ${MEDIA.DESKTOP`
    padding: 0 24px;
  `}
  ${MEDIA.SMALL_PHONE`
    padding: 0 8px;
  `}
`;

export const MoreLayoutWrapper = styled.div`
  overflow-x: hidden;
`;

export const MoreHeaderWrapper = styled.div`
  padding: 24px 0;
  ${MEDIA.DESKTOP`
    padding: 16px 0;
  `}
  box-shadow: 0px 4px 32px rgba(198, 199, 210, 0.2);
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const MoreHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MoreLogoMobile = styled(GatsbyImage)`
  display: none !important;
  ${MEDIA.TABLET`
    display: block !important;
  `}
`;

export const MoreLogoDesktop = styled(GatsbyImage)`
  ${MEDIA.TABLET`
    display: none !important;
  `}
`;

export const MoreHeaderButton = styled.a`
  ${primaryButton({
    size: css`
      padding: 16px 24px 16px;
      font-size: 18px;
      letter-spacing: 1.25px;
    `,
    uppercase: true,
    pill: true,
  })};
  filter: drop-shadow(0px 4px 40px rgba(20, 226, 159, 0.3));
  margin: 0;
  ${MEDIA.DESKTOP`
    ${primaryButton({ size: 'xs', uppercase: true, pill: true })};
    margin: 0;
  `}
`;

export const MoreFooter = styled.footer`
  text-align: center;
  margin-top: 80px;
  margin-bottom: 64px;
  a {
    color: ${medium};
    text-decoration: none;
  }
`;
