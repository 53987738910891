import React from 'react';
import PropTypes from 'prop-types';
import Container from '../structure/container';
import {
  TileWrapper,
  TileImg,
  ContentWrapper,
  ButtonWrapper,
  TileTitle,
  TileParagraph,
  TileSubParagraph,
} from './footerTile.css';
import StoreButtonApple from './store-button/apple';
import StoreButtonGoogle from './store-button/google';
import tileImg from '../../images/footer-tile.png';

const FooterTile = ({
  campaign = '',
  appleCampaign = `badge-${campaign}-down`,
  googleCampaign = `google-badge-${campaign}-down`,
}) => {
  return (
    <Container>
      <TileWrapper>
        <ContentWrapper>
          <TileTitle>Try 30 Day Fitness Now!</TileTitle>
          <TileParagraph>
            See the fantastic results of the 30 Day Fitness Challenge on your
            own body.
          </TileParagraph>
          <TileSubParagraph>Try for Free</TileSubParagraph>
          <ButtonWrapper>
            <StoreButtonApple campaign={appleCampaign} hasMargin />
            <StoreButtonGoogle campaign={googleCampaign} />
          </ButtonWrapper>
        </ContentWrapper>
        <TileImg src={tileImg} alt="Two screens of the 30 Day Fitness app." />
      </TileWrapper>
    </Container>
  );
};
FooterTile.propTypes = {
  campaign: PropTypes.string,
  appleCampaign: PropTypes.string,
  googleCampaign: PropTypes.string,
};

export default FooterTile;
