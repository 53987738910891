import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './shared.css';
import GoogleLight from '../../../images/buttons/google-white.svg';
import GoogleDark from '../../../images/buttons/google-dark.svg';
import useAdjustLink from '../../../hooks/use-adjust-link';

const StoreButtonGoogle = ({ isDark, hasMargin, campaign }) => {
  const ctaRef = useRef();
  const url = useAdjustLink({ campaign, store: 'google' }, ctaRef);
  return (
    <ButtonWrapper
      marginRight={hasMargin}
      href={url}
      ref={ctaRef}
      className="google-play-badge"
    >
      {isDark ? <GoogleDark /> : <GoogleLight />}
    </ButtonWrapper>
  );
};

StoreButtonGoogle.propTypes = {
  hasMargin: PropTypes.bool,
  isDark: PropTypes.bool,
  campaign: PropTypes.string,
};

export default StoreButtonGoogle;
