import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';
import {
  white70,
  accentPrimary,
  white,
  fontSize,
} from '../../../../constants/theme';
import MEDIA from '../../../../helpers/mediaTemplates';
import { buttonMixin } from '../../../../styles/buttons.css';
import { responsiveTypography } from '../../../../styles/typography.css';

export const DownloadCta = styled.div`
  padding: 28px 32px;
  background: ${accentPrimary};
  width: 100%;
  max-width: 580px;
  border-radius: 24px;
  margin: auto;
  position: relative;
  overflow: hidden;
`;

export const DownloadCtaContent = styled.div`
  position: relative;
  z-index: 5;
  max-width: 280px;
  ${MEDIA.TABLET`
    max-width: 200px;
  `}
`;

export const DownloadCtaTitle = styled.h4`
  ${responsiveTypography('Barlow', 800, {
    DEFAULT: fontSize['2.5x'],
    TABLET: fontSize['2x'],
  })}
  line-height: 1.1 !important;
  color: white;
  text-transform: uppercase;
`;

export const DownloadCtaParagraph = styled.p`
  color: ${white70};
  margin: 8px 0;
  line-height: 28px;
  ${fontSize['1.125x']}
`;

export const DownloadCtaImage = styled(GatsbyImage)`
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  img {
    object-fit: contain !important;
    object-position: right center !important;
  }
  ${MEDIA.PHONE`
    right: -100px;
  `}
`;

export const DownloadCtaButton = styled.a`
  ${buttonMixin(accentPrimary, white, '', {
    size: css`
      font-size: 18px;
      padding: 12px 32px;
    `,
    pill: true,
  })}
  margin: 0;
  box-shadow: 0px 6px 43px rgba(166, 164, 164, 0.1);
  font-weight: 500;
  ${MEDIA.TABLET`
    ${buttonMixin(accentPrimary, white, '', { size: 'sm', pill: true })}
    margin: 0;
  `}
`;
