import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout/layout';
import Head from 'components/common/head';
import FAQsLayout from 'components/faqs/layout';
import FooterTile from 'components/common/footerTile';
import Footer from 'components/common/footer';

const FAQs = ({ data }) => (
  <Layout>
    <Head pageTitle="Help Center" />
    <FAQsLayout {...data} />
    <FooterTile appleCampaign="badge-faqs" googleCampaign="google-faqs" />
    <Footer />
  </Layout>
);

FAQs.propTypes = {
  data: PropTypes.shape(FAQsLayout.propTypes),
};

export default FAQs;

export const query = graphql`
  query FAQsQuery {
    ...allFAQs
  }
`;
