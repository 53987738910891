import React from 'react';
import Layout from 'components/layout/layout';
import Head from 'components/common/head';
import CorporateLayout from 'components/corporate/layout';
import FooterTile from 'components/common/footerTile';
import Footer from 'components/common/footer';

const corporate = () => (
  <Layout>
    <Head pageTitle="Corporate Program" />
    <CorporateLayout />
    <FooterTile
      appleCampaign="badge-corporate"
      googleCampaign="google-corporate"
    />
    <Footer />
  </Layout>
);

export default corporate;
