import styled from 'styled-components';
import { barlowFont } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      font-family: ${barlowFont};
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;

      & + li {
        margin-left: 32px;
      }
    }
  }
  ${MEDIA.TABLET`
    display: none;
  `}
`;
