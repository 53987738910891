import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentContext from './paymentContext';
import createPaymentService from './paymentService';

let stripePromise = null;
const getStripePromise = (key) => {
  if (!stripePromise) {
    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

const PaymentProvider = ({ oracle, stripe, children }) => {
  const stripePromise = getStripePromise(stripe.key);
  const service = useMemo(
    () => createPaymentService(oracle.url, oracle.website),
    []
  );

  return (
    <PaymentContext.Provider value={{ stripe, service }}>
      <Elements stripe={stripePromise}>{children}</Elements>
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  oracle: PropTypes.shape({
    url: PropTypes.string,
    website: PropTypes.string,
  }),
  stripe: PropTypes.shape({
    key: PropTypes.string,
    version: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default PaymentProvider;
