import { Link } from 'gatsby';
import { firaFont, firaSpacing, darkest } from '../../../constants/theme';
import styled from 'styled-components';
import { hoverOpacityEffect } from '../../../styles/effects.css';

export const BadgeWrapper = styled(Link)`
  ${hoverOpacityEffect}
  display: inline-block;
  padding: 8px 16px;
  position: relative;

  font: 500 16px/1 ${firaFont};
  text-transform: uppercase;
  letter-spacing: ${firaSpacing};
  color: ${(props) => props.color || darkest};
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 16px;
    background: currentColor;
    opacity: 0.15;
  }
`;
