import React from 'react';
import PropTypes from 'prop-types';
import { MetaWrapper, Name, Date, Copy, CardWrapper } from './item.css';

const ReviewItem = ({ copy, name, date }) => (
  <CardWrapper>
    <Copy>{copy}</Copy>
    <MetaWrapper>
      <Name>{name}</Name>
      <Date>{date}</Date>
    </MetaWrapper>
  </CardWrapper>
);

ReviewItem.propTypes = {
  copy: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
};

export default ReviewItem;
