import styled from 'styled-components';
import {
  lightest,
  maxCardWidth,
  shadowLight,
  white70,
} from '../../../constants/theme';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${white70};
  z-index: 1;

  display: flex;
  padding: 16px;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: ${maxCardWidth};
  background: white;
  border-radius: 12px;
  border: 1px solid ${lightest};
  box-shadow: ${shadowLight};
  padding: 32px;
  margin: auto;
  text-align: center;
`;
