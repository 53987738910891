import styled from 'styled-components';
import {
  MainTitle,
  SecondaryTitle,
  MainParagraph,
} from '../../styles/typography.css';
import Background from '../../images/landings/background-image.jpg';

export const LandingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-items: flex-end;
`;

export const ColumnHalf = styled.div`
  display: flex;
  width: 50%;
  &:first-child {
    overflow-y: scroll;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 240px;
  margin-bottom: 32px;
`;

export const VpWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 48px 32px;
`;

export const ArtworkWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: gray;
  background-image: url(${Background});
  background-size: cover;
  background-position: left center;
`;

export const QrWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  img {
    max-width: 160px;
  }
`;

export const Title = styled(MainTitle)`
  line-height: 72px;
  span {
    color: #21d890;
  }
`;

export const Subtitle = styled(SecondaryTitle)`
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 8px;
  span {
    color: #21d890;
  }
`;

export const Paragraph = styled(MainParagraph)`
  strong {
    color: #4f4f5b;
    font-weight: 500;
  }
`;
