import React, { useRef } from 'react';
import useAdjustLink from '../../../../hooks/use-adjust-link';
import { MoreContainer } from '../../more.css';
import { MoreCopyButton, MoreTitle } from '../shared.css';

const Text = () => {
  const ctaRef = useRef();
  const storeLink = useAdjustLink(
    {
      campaign: 'badge-footer-cta-download',
      store: 'apple',
    },
    ctaRef
  );
  return (
    <MoreContainer isNarrow>
      <MoreTitle as="h2">
        Millions of people are already training with
        <span> 30 Day Fitness</span>.
      </MoreTitle>
      <MoreCopyButton href={storeLink} ref={ctaRef} rel="nofollow">
        Get The App Now
      </MoreCopyButton>
    </MoreContainer>
  );
};

export default Text;
