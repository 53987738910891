import styled from 'styled-components';
import {
  barlowSemiCondensed,
  MainParagraph,
} from '../../../../../styles/typography.css';
import {
  buttonGradient,
  darkest,
  dark,
  fontSize,
  medium,
  shadowLight,
  white,
} from '../../../../../constants/theme';
import MEDIA from '../../../../../helpers/mediaTemplates';
import responsiveMixin from '../../../../../styles/responsiveMixin';
import { TransparentButton } from '../../../ui/button.css';

export const StepTextWrapper = styled.div`
  padding: 0 8px;
  ${MEDIA.MIN_SMALL_PHONE`
    padding: 0 16px;
  `}
  ${MEDIA.MIN_PHONE`
    padding: 0 32px;
  `}
`;

export const StepTitle = styled.h2`
  ${barlowSemiCondensed()}
  ${responsiveMixin({
    DEFAULT: fontSize['2x'],
    MIN_SMALL_PHONE: fontSize['2.25x'],
    MIN_PHONE: fontSize['2.5x'],
  })}
  color: ${darkest};

  margin-top: 8px;
  margin-bottom: 24px;
`;

export const StepSubtitle = styled(MainParagraph)`
  color: ${medium};
  white-space: pre-wrap;
  b {
    font-weight: 600;
    color: ${dark};
  }
`;

export const StepContentWrapper = styled.div`
  ${responsiveMixin({
    DEFAULT: 'padding: 32px 16px;',
    MIN_SMALL_PHONE: 'padding: 40px 24px;',
  })}

  > * + * {
    margin-top: 40px;
  }
`;

export const StepButton = styled(TransparentButton)`
  color: ${({ isPressed }) => (isPressed ? white : darkest)};
  background: ${({ isPressed }) => (isPressed ? buttonGradient : white)};
  font-weight: ${({ isPressed }) => !isPressed && '400'};
  box-shadow: ${shadowLight};
`;
