import { css } from 'styled-components';

/* COLORS */

// GRAYS
export const black = '#2B2733';
export const darkest = '#4F4F5B';
export const dark = 'rgba(93, 90, 108, 0.8)';
export const medium = '#A5A7B7';
export const light = '#C6C7D2';
export const lightest = '#ECECEC';
export const white70 = 'rgba(255,255,255,.7)';
export const white = '#FFF';
// ACCENTS
export const accentPrimary = '#13D68F';
export const accentSecondary = '#428EFF';
export const accentOrange = '#FF9A62';
export const ratingsOrange = '#F85036';
// BACKGROUNDS
export const backgroundPrimary = '#FCFCFC';
export const backgroundSecondary = '#F8F7FA';
export const backgroundTertiary =
  'linear-gradient(180deg, #FCFCFC 0%, #F4F4F6 100%)';
// GRADIENTS
export const darkGradient = 'linear-gradient(180deg, #6B6C82 0%, #3B3C59 100%)';
export const endButtonGradient = '#0BCA84';
export const buttonGradient = `linear-gradient(180deg, #19F2B1 0%, ${endButtonGradient} 100%)`;
export const buttonDisabledGradient =
  'linear-gradient(180deg, #B7ECDA 0%, #9CE3C9 100%)';
// SHADOWS
export const shadowLight = '0px 4px 32px rgba(198, 199, 210, 0.2)';
export const shadowGreen = '0px 4px 30px rgba(19, 214, 143, 0.2)';

/* SPACING */

export const containerWidth = '1184px';
export const containerSmallWidth = '1024px';
export const containerMin = '980px';
export const blogWidth = '580px';
export const sectionGap = '64px';
export const sectionGapMobile = '64px';
export const maxCardWidth = '480px';

export const barlowSpacing = '0.5px';
export const firaSpacing = '0.2px';

/* FONTS */

export const firaFont =
  '"Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const barlowFont =
  '"Barlow Semi Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const barlowFontHeavy =
  '"Barlow Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

/* FONT SIZES */

export const fontSize = {
  '1x': css`
    font-size: 16px;
    line-height: 25px;
  `,
  '1.125x': css`
    font-size: 18px;
    line-height: 28px;
  `,
  '1.25x': css`
    font-size: 20px;
    line-height: 30px;
  `,
  '1.5x': css`
    font-size: 24px;
    line-height: 36px;
  `,
  '2x': css`
    font-size: 32px;
    line-height: 38px;
  `,
  '2.25x': css`
    font-size: 36px;
    line-height: 38px;
  `,
  '2.5x': css`
    font-size: 40px;
    line-height: 46px;
  `,
  '3x': css`
    font-size: 48px;
    line-height: 52px;
  `,
  '3.5x': css`
    font-size: 56px;
    line-height: 60px;
  `,
};
