import styled from 'styled-components';
import MEDIA from '../../../helpers/mediaTemplates';

export const GalleryTemplate = styled.div`
  position: relative;
  padding-bottom: 45vh;
  padding-top: 64px;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`;

export const GalleryWrapper = styled.div`
  position: absolute;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
`;
export const GalleryColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  will-change: transform;
  &:last-child {
    margin-right: 0;
  }
  &:nth-child(2) {
    margin-top: 20vh;
  }
  &:nth-child(3) {
    margin-top: 13vh;
  }
  &:nth-child(4) {
    margin-top: 7vh;
  }
  &:nth-child(5) {
    margin-top: 15vh;
  }
  ${MEDIA.DESKTOP`
    margin-right: 16px;
  `};
`;
export const GalleryItem = styled.div`
  width: 335px;
  height: auto;
  overflow: hidden;
  background: url(${(props) => props.background});
  margin-bottom: 24px;
  box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.24);
  border-radius: 10px;
  ${MEDIA.DESKTOP`
    width: 280px;
    margin-bottom: 16px;
    border-radius: 8px;
  `};
  ${MEDIA.PHONE`
    width: 200px;
    margin-bottom: 16px;
    border-radius: 4px;
  `};
`;
