import styled, { css } from 'styled-components';
import {
  buttonDisabledGradient,
  buttonGradient,
  fontSize,
  shadowGreen,
  white,
} from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import { BaseButton, buttonBlock } from '../../../styles/buttons.css';
import responsiveMixin from '../../../styles/responsiveMixin';
import { barlowSemiCondensed } from '../../../styles/typography.css';

export const TransparentButton = styled(BaseButton)`
  ${buttonBlock({ full: true })}
  ${barlowSemiCondensed()}

  ${responsiveMixin({
    DEFAULT: 'padding: 12px 16px 16px;',
    MIN_SMALL_PHONE: 'padding: 16px 24px 20px;',
    MIN_PHONE: 'padding: 16px 32px 20px',
  })}

  ${fontSize['1.25x']}
  ${MEDIA.MIN_SMALL_PHONE`
    font-size: 24px;
    line-height: 30px;
  `}
`;

const disabledPrimaryButton = css`
  background: ${buttonDisabledGradient};
  box-shadow: none;
  cursor: not-allowed;
`;

export const PrimaryButton = styled(TransparentButton)`
  color: ${white};
  background: ${buttonGradient};
  box-shadow: ${shadowGreen};
  :disabled {
    ${disabledPrimaryButton}
  }
  ${({ looksDisabled }) =>
    looksDisabled &&
    css`
      ${disabledPrimaryButton}
    `}
`;
