import styled from 'styled-components';
import {
  MainTitle,
  LeadParagraph,
  MainParagraph,
} from '../../../styles/typography.css';

export const TeaserTitle = MainTitle;

export const TeaserLeadParagraph = LeadParagraph;

export const TeaserMainParagraph = styled(MainParagraph)`
  margin-bottom: 32px;
`;
