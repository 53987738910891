import styled from 'styled-components';
import {
  black,
  dark,
  blogWidth,
  accentPrimary,
  barlowSpacing,
  firaSpacing,
  fontSize,
} from '../../constants/theme';
import { barlowSemiCondensed } from '../../styles/typography.css';
import MEDIA from '../../helpers/mediaTemplates';

export const LegalContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  max-width: ${blogWidth};
  margin: 0 auto;
`;

export const LegalCopy = styled.div`
  margin: 48px auto;
  color: ${dark};
  text-align: left;
  b,
  strong {
    font-weight: 600;
  }
  i {
    font-style: italic;
  }
  p,
  ul,
  table {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: ${firaSpacing};
    margin-bottom: 24px;
  }
  ol,
  ul {
    list-style: initial;
    padding-inline-start: 24px;
    li {
      margin-bottom: 16px;
      padding-left: 8px;
    }
  }
  ol {
    list-style: decimal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: ${firaSpacing};
    color: ${accentPrimary};
    margin-bottom: 24px;
  }
  table {
    th {
      font-weight: 700;
    }
    td {
      font-size: 16px;
      line-height: 24px;
    }
    td,
    th {
      padding: 8px 16px;
      border-bottom: 1px solid #eee;
    }
  }
  h1,
  h2,
  h3 {
    color: ${black};
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: ${barlowSpacing};
    margin-bottom: 24px;
    ${MEDIA.TABLET`
    font-size: 36px;
    line-height: 38px;
    margin: 76px auto 32px;
  `};
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
    #cookie-policy-title,
    .ot-sdk-subgroup,
    .ot-sdk-cookie-policy-group-desc {
      display: none;
    }
    * {
      color: ${dark};
    }
    a {
      color: ${accentPrimary};
    }
    .ot-sdk-cookie-policy-group {
      ${fontSize['1.25x']}
      color: ${dark};
      ${barlowSemiCondensed()}
      margin-bottom: 8px;
    }
    .ot-table-header {
      ${fontSize['1.125x']}
      color: ${dark};
      ${barlowSemiCondensed()}
    }
  }
`;
