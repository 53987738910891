import React from 'react';
import PropTypes from 'prop-types';
import Container from '../structure/container';
import Section from '../structure/section';
import Img from 'gatsby-image';
import {
  FeatureWrapper,
  FeatureContent,
  FeatureTitle,
  FeatureParagraph,
  ButtonWrapper,
  FeatureArtwork,
} from './feature.css';
import StoreButtonApple from '../common/store-button/apple';
import StoreButtonGoogle from '../common/store-button/google';

const Feature = ({
  title,
  paragraph,
  image,
  isDarker,
  isReverse,
  campaignName,
}) => (
  <Section isDarker={isDarker}>
    <Container isSmall>
      <FeatureWrapper isReverse={isReverse}>
        <FeatureContent>
          <FeatureTitle
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <FeatureParagraph>{paragraph}</FeatureParagraph>
          <ButtonWrapper>
            <StoreButtonApple
              hasMargin
              isDark={isDarker}
              campaign={campaignName}
            />
            <StoreButtonGoogle isDark={isDarker} campaign={campaignName} />
          </ButtonWrapper>
        </FeatureContent>
        <FeatureArtwork isReverse={isReverse}>
          <Img fluid={image} />
        </FeatureArtwork>
      </FeatureWrapper>
    </Container>
  </Section>
);

Feature.propTypes = {
  title: PropTypes.node.isRequired,
  paragraph: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  isDarker: PropTypes.bool,
  isReverse: PropTypes.bool,
  campaignName: PropTypes.string,
};

export default Feature;
