import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { darkest } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const GoalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GoalsWrapperTitle = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${darkest};
  margin-bottom: 16px;
`;

export const GoalsWrapperRail = styled.div`
  width: 100%;
  ${MEDIA.TABLET`
    width: 100vw;
    margin: 0 -24px;
  `}
  ${MEDIA.SMALL_PHONE`
    margin: 0 -8px;
  `}
`;

export const GoalsWrapperScroller = styled.div`
  display: flex;
  width: auto;
  overflow-x: scroll;
  ${MEDIA.TABLET`
    padding: 0 24px;
  `}
  ${MEDIA.SMALL_PHONE`
    padding-left: 8px;
  `}
`;

export const GoalsCard = styled.a`
  height: 60px;
  min-width: 164px;
  padding-right: 16px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 16px;
  ${MEDIA.TABLET`
    margin-right: 8px;
  `}
  &:nth-child(1) {
    background-color: #f5ac10;
  }
  &:nth-child(2) {
    background-color: #8673ff;
  }
  &:nth-child(3) {
    background-color: #f48b8b;
  }
  &:nth-child(4) {
    background-color: #53d6ce;
  }
`;

export const GoalsCardImage = styled(GatsbyImage)`
  width: auto;
  height: 100%;
`;

export const GoalsCardTitle = styled.p`
  color: white;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.25px;
  font-weight: 500;
`;
