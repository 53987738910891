import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  GalleryTemplate,
  GalleryWrapper,
  GalleryColumn,
  GalleryItem,
} from './gallery.css';

const ExercisesGallery = ({ workouts }) => (
  <GalleryTemplate>
    <GalleryWrapper>
      <GalleryColumn>
        {workouts.slice(0, 3).map((item, i) => (
          <GalleryItem key={i}>
            <Img fluid={item.src.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </GalleryColumn>
      <GalleryColumn>
        {workouts.slice(3, 6).map((item, i) => (
          <GalleryItem key={i}>
            <Img fluid={item.src.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </GalleryColumn>
      <GalleryColumn>
        {workouts.slice(6, 9).map((item, i) => (
          <GalleryItem key={i}>
            <Img fluid={item.src.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </GalleryColumn>
      <GalleryColumn>
        {workouts.slice(9, 12).map((item, i) => (
          <GalleryItem key={i}>
            <Img fluid={item.src.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </GalleryColumn>
      <GalleryColumn>
        {workouts.slice(12, 15).map((item, i) => (
          <GalleryItem key={i}>
            <Img fluid={item.src.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </GalleryColumn>
    </GalleryWrapper>
  </GalleryTemplate>
);

ExercisesGallery.propTypes = {
  workouts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExercisesGallery;
