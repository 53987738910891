import React from 'react';
import FreeTrialLayout from 'components/landings/freeTrial';

const freeTrial = () => (
  <FreeTrialLayout
    heading="get your offer"
    subheading="30 Day Fitness free trial"
    closing="our personalized workouts"
  />
);

export default freeTrial;
