import styled from 'styled-components';
import { backgroundPrimary, white } from '../../constants/theme';

export const PerspectiveWrapper = styled.div`
  background: ${backgroundPrimary};
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        position: fixed;
        perspective: 800px;
        height: 100%;
        width: 100%;
      `;
    }
    return;
  }}
`;

export const ScrollContainer = styled.div`
  transition: transform 0.4s;
  transform-origin: 50% 50%;
  z-index: 5;
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        transform: translateZ(-400px) translateX(70%) rotateY(-35deg);
        position: absolute;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        cursor: pointer;
        backface-visibility: hidden;
        box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
      `;
    }
    return;
  }}
  overflow-x: hidden;
`;

export const Overlay = styled.div`
  background-color: ${white};
  display: block;
  opacity: 0;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 300ms ease;
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        pointer-events: initial;
        opacity: 0.7;
      `;
    }
    return;
  }}
`;
