import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import {
  TeaserTitle,
  TeaserLeadParagraph,
  TeaserMainParagraph,
} from './teaser.css';

const BeyondTeaser = ({ title, lead, main }) => (
  <>
    <TeaserTitle
      isCenteredMobile
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
    <TeaserLeadParagraph isCenteredMobile>{lead}</TeaserLeadParagraph>
    <TeaserMainParagraph isCenteredMobile>{main}</TeaserMainParagraph>
  </>
);

BeyondTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
};

export const query = graphql`
  fragment BeyondTeaser on AboutJson {
    title
    lead
    main
  }
`;

export default BeyondTeaser;
