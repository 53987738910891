import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../../structure/container';
import Section from '../../structure/section';
import Head from '../../common/head';
import Layout from '../../layout/layout';
import FooterTile from '../../common/footerTile';
import Footer from '../../common/footer';
import ArticlesList from '../articlesList';
import CategoryBadge from '../category/badge';
import TagBadge from '../tag/badge';
import ShareButtons from './shareButtons';

import useOptimize from '../../../hooks/use-optimize';
import { getTrackingLink } from '../../../helpers/trackingLink';

import {
  BlogContainer,
  BlogTitle,
  BlogCopy,
  MetaWrapper,
  MetaCopy,
  BadgesWrapper,
  Dot,
  SocialCopy,
  ImageWrapper,
} from './layout.css';

const PostLayout = ({ data }) => {
  const markdownRemark = data.mainArticle;
  let post = markdownRemark.frontmatter;
  const title = `Read ${post.title} `;
  const url = `https://30dayfitness.app/${post.categories}/${post.slug}/`;
  const tags = post.tags;

  const copyRef = useRef();
  useOptimize(() => {
    if (copyRef.current) {
      copyRef.current
        .querySelectorAll('[href^="https://app.adjust.com/amwl9o4"]')
        .forEach(async (element) => {
          const href = element.href;
          const anonymousLink = new URL(href);
          const link = await getTrackingLink(
            anonymousLink.searchParams.get('campaign'),
            'apple'
          );
          // Update only if the DOM hasn't changed while we were waiting for the tracking link
          if (element.href == href) {
            element.href = link;
          }
        });
    }
  }, [copyRef.current]);

  return (
    <Layout>
      <Head
        pageTitle={post.title}
        imageUrl={post.coverImage.publicURL}
        pageDescription={post.description || markdownRemark.excerpt}
      />
      <Section>
        <Container>
          <BlogContainer>
            <MetaWrapper>
              <MetaCopy>{post.date}</MetaCopy>
            </MetaWrapper>
            <BlogTitle isCenteredMobile>
              {post.firstHeading || post.title}
            </BlogTitle>
            <BadgesWrapper>
              <CategoryBadge category={post.categories} />
              {tags && (
                <>
                  <Dot />
                  {tags.map((tag) => (
                    <TagBadge tag={tag} key={tag} />
                  ))}
                </>
              )}
            </BadgesWrapper>
          </BlogContainer>
          <ImageWrapper>
            <Img
              fluid={post.coverImage.childImageSharp.fluid}
              alt={post.coverImageAlt || post.title}
            />
          </ImageWrapper>
          <BlogContainer>
            <BlogCopy
              ref={copyRef}
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html,
              }}
            />
            <SocialCopy isCenteredMobile>
              Did you enjoy this article? Share it!
            </SocialCopy>
            <ShareButtons title={title} url={url} tags={tags} />
          </BlogContainer>
        </Container>
      </Section>
      {!!data.relatedArticles.edges.length && (
        <Section hasNoPadding>
          <Container>
            <ArticlesList
              title="Related Articles"
              articles={data.relatedArticles.edges}
            />
          </Container>
        </Section>
      )}
      <FooterTile campaign="article" />
      <Footer />
    </Layout>
  );
};

PostLayout.propTypes = {
  data: PropTypes.any.isRequired,
};

export default PostLayout;

export const query = graphql`
  query PostQuery($slug: String!, $relatedArticles: [String]) {
    mainArticle: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        firstHeading
        date(formatString: "D MMMM YYYY")
        slug
        categories
        tags
        description
        coverImage {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 90
              srcSetBreakpoints: [320, 480, 600]
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        coverImageAlt
      }
    }
    relatedArticles: allMarkdownRemark(
      filter: { frontmatter: { slug: { in: $relatedArticles } } }
    ) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
  }
`;
