import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { HeaderBar, HeaderWrapper } from './header.css';
import HeaderNav from './header/nav';
import HeaderMobile from './header/mobile';
import logo from '../../images/logo-main.png';
import logoMobile from '../../images/logo-mobile.png';
import Container from '../structure/container';
import BREAKPOINTS from '../../constants/breakpoints';
import useViewport from '../../hooks/use-viewport';

const Header = ({ setIsOpen, links }) => {
  const { width } = useViewport();
  const isMobile = width <= BREAKPOINTS.TABLET;
  return (
    <HeaderBar>
      <Container>
        <HeaderWrapper>
          <Link className="logo" to="/">
            <img src={isMobile ? logoMobile : logo} alt="30 Day Fitness Logo" />
          </Link>
          {isMobile ? (
            <HeaderMobile setIsOpen={setIsOpen} />
          ) : (
            <HeaderNav links={links} />
          )}
        </HeaderWrapper>
      </Container>
    </HeaderBar>
  );
};

Header.propTypes = {
  setIsOpen: PropTypes.func,
  links: PropTypes.array.isRequired,
};

export default Header;
