import React from 'react';
import PropTypes from 'prop-types';
import { BadgeWrapper } from './badge.css';
import useCategory from '../../../hooks/use-category';

const CategoryBadge = ({ category }) => {
  const { color, url, name } = useCategory(category);
  return (
    <BadgeWrapper to={url} color={color}>
      {name}
    </BadgeWrapper>
  );
};

CategoryBadge.propTypes = {
  category: PropTypes.string.isRequired,
};

export default CategoryBadge;
