/**
 * @param {Response} res
 */
const parseBody = async (res) => {
  const body = await res.json();
  if (res.status >= 300) {
    if (!body.error) {
      return {
        error: true,
        user_message:
          // eslint-disable-next-line quotes
          "There's been an unknown internet error. We're sorry for the inconvenience!",
        dev_message: `The server responded with a ${res.status} error status.`,
      };
    }
  }
  return body;
};

const createPaymentService = (oracleUrl, oracleWebsite) => {
  /**
   * @param {string} email
   * @returns {Promise<
   *  | {customer: any; setupIntent: any, error?: undefined}
   *  | {customer?: undefined; setupIntent?: undefined; error: any}
   * >}
   */
  const setupIntent = (email) => {
    return fetch(`${oracleUrl}/stripe/customer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, website: oracleWebsite }),
    }).then(parseBody);
  };

  /**
   * @param {string} customerId
   * @param {string} paymentMethodId
   * @param {string} priceId
   * @returns {Promise<
   *  | {subscription: any; error?: undefined}
   *  | {subscription?: undefined; error: any}
   * >}
   */
  const subscribe = (
    customerId,
    paymentMethodId,
    priceId,
    onboardingInformation
  ) => {
    return fetch(`${oracleUrl}/stripe/subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer_id: customerId,
        price_id: priceId,
        payment_method_id: paymentMethodId,
        onboarding_information: onboardingInformation,
        website: oracleWebsite,
      }),
    }).then(parseBody);
  };

  return { setupIntent, subscribe };
};

export default createPaymentService;
