import { useStaticQuery, graphql } from 'gatsby';

const useTag = (slug) => {
  const { allTags } = useStaticQuery(graphql`
    {
      allTags: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(tags)/" } }
      ) {
        edges {
          node {
            frontmatter {
              name
              category
              slug
            }
          }
        }
      }
    }
  `);
  const tagEdge = allTags.edges.find(
    (edge) => edge.node.frontmatter.slug === slug
  );
  if (tagEdge) {
    const tag = tagEdge.node.frontmatter;
    tag.url = `/${tag.category.toLowerCase()}/${tag.slug}/`;
    return tag;
  }
};

export default useTag;
