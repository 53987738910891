import styled from 'styled-components';
import { SecondaryTitle, MainParagraph } from '../../styles/typography.css';
import { RowWrapper } from '../structure/row.css';
import {
  light,
  black,
  dark,
  backgroundPrimary,
  backgroundSecondary,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { primaryButton } from '../../styles/buttons.css';

export const CorporateWrapper = styled(RowWrapper)`
  height: 100vh;
  min-height: 900px;
  max-height: 1024px;
  margin: -80px auto 0;
  width: auto;
  z-index: 5;
  ${MEDIA.DESKTOP`
    margin: -40px auto 0;
  `};
  ${MEDIA.TABLET`
    max-width: 540px;
    min-height: auto;
    height: auto;
    margin: 0 auto 120px;
    padding-top: 120px;
 `};
`;

export const ContentWrapper = styled.div`
  max-width: 580px;
  margin: auto 0;
  ${MEDIA.WIDESCREEN`
    max-width: 480px;
  `};
  ${MEDIA.DESKTOP`
    max-width: 400px;
  `}
  ${MEDIA.TABLET`
    max-width: 580px;
  `}
`;

export const FeaturesWrapper = styled.div`
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  ${MEDIA.DESKTOP`
    gap: 16px;
  `}
  ${MEDIA.TABLET`
    display: none;
 `};
`;

export const CardWrapper = styled.div`
  position: relative;
  background: ${backgroundPrimary};
  border-radius: 10px;
  padding: 16px 32px 24px;
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 80%;
    height: 90%;
    top: 20%;
    left: 10%;
    background: rgba(220, 220, 220, 0.7);
    filter: blur(40px);
    border-radius: 70px;
  }
  ${MEDIA.DESKTOP`
    padding: 16px 16px 24px;
  `}
`;

export const Icon = styled.div`
  margin-bottom: 8px;
  text-align: center;
`;

export const CardContent = styled(MainParagraph)`
  text-align: center;
  line-height: 26px;
  ${MEDIA.WIDESCREEN`
    font-size: 16px;
  `};
`;

export const CorporateTitle = styled(SecondaryTitle)`
  ${MEDIA.WIDESCREEN`
    font-size: 40px;
    line-height: 48px;
  `};
  ${MEDIA.TABLET`
    text-align: center;
 `};
`;

export const CorporateParagraph = styled(MainParagraph)`
  ${MEDIA.TABLET`
    text-align: center;
 `};
`;

export const ParagraphSmall = styled(MainParagraph)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const FormTitle = styled(SecondaryTitle)`
  font-size: 40px;
  line-height: 46px;
  ${MEDIA.WIDESCREEN`
    font-size: 32px;
    line-height: 38px;
  `};
  ${MEDIA.DESKTOP`
    font-size: 24px;
    line-height: 32px;
 `};
`;

export const FormWrapper = styled.div`
  position: relative;
  margin: auto 0;
  padding: 32px;
  max-width: 480px;
  border-radius: 16px;
  background: ${backgroundPrimary};
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    top: 30%;
    left: 0;
    background: rgba(220, 220, 220, 0.7);
    filter: blur(90px);
    border-radius: 70px;
  }
  ${MEDIA.WIDESCREEN`
    max-width: 400px;
  `};
  ${MEDIA.DESKTOP`
    max-width: 320px;
    padding: 20px;
  `}
  ${MEDIA.TABLET`
    max-width: 540px;
 `};
  ${MEDIA.PHONE`
    padding: 20px;
    margin: 0 -20px;
  `};
`;

export const Form = styled.div`
  width: 100%;
  max-width: 400px;
  .mc-field-group {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    label {
      font-weight: 600;
      color: ${dark};
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
    }
    input {
      color: ${black};
      font-family: inherit;
      padding: 16px;
      font-size: 18px;
      line-height: 18px;
      background: ${backgroundSecondary};
      border: 1px solid ${light};
      border-radius: 9px;
      &::placeholder {
        color: ${light};
      }
      ${MEDIA.DESKTOP`
        padding: 12px 16px;
      `};
    }
  }
  .button {
    ${primaryButton({ full: true })}
  }
  ${MEDIA.TABLET`
    max-width: 540px;
  `};
`;
