import React, { useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import useAdjustLink from '../../../hooks/use-adjust-link';
import {
  GoalsWrapper,
  GoalsWrapperTitle,
  GoalsWrapperRail,
  GoalsWrapperScroller,
  GoalsCard,
  GoalsCardImage,
  GoalsCardTitle,
} from './goals.css';

const Goals = () => {
  const flatBellyRef = useRef();
  const flatBellyLink = useAdjustLink(
    {
      campaign: 'flat-belly-download',
      store: 'apple',
    },
    flatBellyRef
  );

  const tonedLegsRef = useRef();
  const tonedLegsLink = useAdjustLink(
    {
      campaign: 'toned-legs-download',
      store: 'apple',
    },
    tonedLegsRef
  );

  const slimArmsRef = useRef();
  const slimArmsLink = useAdjustLink(
    {
      campaign: 'slim-arms-download',
      store: 'apple',
    },
    slimArmsRef
  );

  const weightLossRef = useRef();
  const weightLossLink = useAdjustLink(
    {
      campaign: 'weight-loss-download',
      store: 'apple',
    },
    weightLossRef
  );

  return (
    <GoalsWrapper>
      <GoalsWrapperTitle>Hit your goal in 30 days!</GoalsWrapperTitle>
      <GoalsWrapperRail>
        <GoalsWrapperScroller>
          <StaticQuery
            query={graphql`
              {
                core: file(relativePath: { eq: "more/core.png" }) {
                  childImageSharp {
                    fixed(height: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                lowerBody: file(relativePath: { eq: "more/lower-body.png" }) {
                  childImageSharp {
                    fixed(height: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                upperBody: file(relativePath: { eq: "more/upper-body.png" }) {
                  childImageSharp {
                    fixed(height: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                fullBody: file(relativePath: { eq: "more/full-body.png" }) {
                  childImageSharp {
                    fixed(height: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            `}
            render={({ core, lowerBody, upperBody, fullBody }) => (
              <>
                <GoalsCard
                  href={flatBellyLink}
                  ref={flatBellyRef}
                  rel="nofollow"
                >
                  <GoalsCardImage
                    fixed={core.childImageSharp.fixed}
                    alt="Core"
                  />
                  <GoalsCardTitle>Flat Belly</GoalsCardTitle>
                </GoalsCard>
                <GoalsCard
                  href={tonedLegsLink}
                  ref={tonedLegsRef}
                  rel="nofollow"
                >
                  <GoalsCardImage
                    fixed={lowerBody.childImageSharp.fixed}
                    alt="Lower body"
                  />
                  <GoalsCardTitle>Toned Legs</GoalsCardTitle>
                </GoalsCard>
                <GoalsCard href={slimArmsLink} ref={slimArmsRef} rel="nofollow">
                  <GoalsCardImage
                    fixed={upperBody.childImageSharp.fixed}
                    alt="Upper body"
                  />
                  <GoalsCardTitle>Slim Arms</GoalsCardTitle>
                </GoalsCard>
                <GoalsCard
                  href={weightLossLink}
                  ref={weightLossRef}
                  rel="nofollow"
                >
                  <GoalsCardImage
                    fixed={fullBody.childImageSharp.fixed}
                    alt="Full body"
                  />
                  <GoalsCardTitle>Weight Loss</GoalsCardTitle>
                </GoalsCard>
              </>
            )}
          />
        </GoalsWrapperScroller>
      </GoalsWrapperRail>
    </GoalsWrapper>
  );
};

export default Goals;
