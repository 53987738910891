import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  AnimatePresence,
  MotionConfig,
  AnimationFeature,
  ExitFeature,
  m,
} from 'framer-motion';
import { timeout } from 'constants/transition';

class Transition extends PureComponent {
  render() {
    const { children, location } = this.props;
    return (
      <MotionConfig features={[AnimationFeature, ExitFeature]}>
        <AnimatePresence initial={false} exitBeforeEnter>
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: timeout, delayChildren: timeout }}
            key={location.pathname}
          >
            {children}
          </m.div>
        </AnimatePresence>
      </MotionConfig>
    );
  }
}

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Transition;
