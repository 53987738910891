import styled from 'styled-components';
import { darkest, backgroundSecondary } from '../../../constants/theme';
import { primaryButton } from '../../../styles/buttons.css';

export const Container = styled.nav`
  display: flex;
`;

export const DownloadButton = styled.a`
  ${primaryButton({ pill: true, size: 'xs', uppercase: true })}
  margin: auto;
`;

export const VeggieBurger = styled.button`
  position: relative;
  margin-left: 24px;
  width: 48px;
  height: 48px;
  padding: 5px;
  border-radius: 50%;
  background: ${backgroundSecondary};
  font-size: 0;
  &::before,
  &::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 3px;
    margin: 2px 0;
    background: ${darkest};
    border-radius: 10px;
  }
`;
