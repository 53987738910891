import styled, { css } from 'styled-components';

export const LoadingWrapper = styled.div`
  opacity: 1;
  transition: 0.3s ease-out opacity;
  ${({ isLoading }) =>
    isLoading &&
    css`
      transition-timing-function: ease-in;
      cursor: wait;
      opacity: 0.5;
      > * {
        pointer-events: none;
      }
    `}
`;
