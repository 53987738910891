import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import useAdjustLink from '../../hooks/use-adjust-link';
import FooterImprint from '../common/footer/imprint';
import Head from '../common/head';
import MoreFooterText from './more/footer/text';
import MoreFooterTile from './more/footer/tile';
import {
  MoreContainer,
  MoreLayoutWrapper,
  MoreFooter,
  MoreHeaderWrapper,
  MoreHeader,
  MoreLogoMobile,
  MoreLogoDesktop,
  MoreHeaderButton,
} from './more.css';

const MoreLayout = ({ children, isNarrow, footerStyle }) => {
  const ctaRef = useRef();
  const storeLink = useAdjustLink(
    { campaign: 'navbar', store: 'apple' },
    ctaRef
  );

  return (
    <MoreLayoutWrapper>
      <Head
        pageTitleFull="30 Day Fitness Challenge App: workouts at home to get into shape"
        pageDescription="With the 30 Day Fitness Challenge app you can lose weight and get your body toned and fit in a few weeks. Personalize your workout plan and get into shape"
        imageUrl="/social-more.jpg"
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <MoreHeaderWrapper>
        <MoreContainer isNarrow={isNarrow}>
          <MoreHeader>
            <StaticQuery
              query={graphql`
                {
                  file(relativePath: { eq: "logo-main.png" }) {
                    childImageSharp {
                      mobile: fixed(height: 35, quality: 90) {
                        ...GatsbyImageSharpFixed
                      }
                      desktop: fixed(height: 45, quality: 90) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              `}
              render={({ file }) => (
                <>
                  <MoreLogoMobile
                    fixed={file.childImageSharp.mobile}
                    alt="30 Day Fitness logo"
                  />
                  <MoreLogoDesktop
                    fixed={file.childImageSharp.desktop}
                    alt="30 Day Fitness logo"
                  />
                  <MoreHeaderButton
                    href={storeLink}
                    ref={ctaRef}
                    rel="nofollow"
                  >
                    Install Now
                  </MoreHeaderButton>
                </>
              )}
            />
          </MoreHeader>
        </MoreContainer>
      </MoreHeaderWrapper>

      <main>{children}</main>

      <section>
        <MoreContainer>
          {footerStyle == 'text' ? <MoreFooterText /> : ''}
          {footerStyle == 'tile' ? <MoreFooterTile /> : ''}
        </MoreContainer>
      </section>

      <MoreFooter>
        <MoreContainer>
          <FooterImprint />
        </MoreContainer>
      </MoreFooter>
    </MoreLayoutWrapper>
  );
};

MoreLayout.propTypes = {
  children: PropTypes.node,
  isNarrow: PropTypes.bool,
  footerStyle: PropTypes.oneOf(['text', 'tile']),
};

export default MoreLayout;
