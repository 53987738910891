import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const RowWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isRowReverse }) =>
    isRowReverse ? 'row-reverse' : 'row'};
  ${MEDIA.TABLET`
    flex-direction: ${({ isColumnReverse }) =>
      isColumnReverse ? 'column-reverse' : 'column'};
  `};
`;
