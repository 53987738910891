import { Link } from 'gatsby';
import styled from 'styled-components';
import { light } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const AnchorLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 0 24px;
  color: ${light};
  transform: translateX(-48px);
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  ${MEDIA.TABLET`
    display: none;
  `}
`;

export const AnchorWrapper = styled.div`
  position: relative;
  &:hover ${AnchorLink} {
    opacity: 100;
    transition-delay: 1s;
    pointer-events: all;
  }
`;
