import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';
import {
  medium,
  barlowFont,
  barlowSpacing,
  maxCardWidth,
} from '../../constants/theme';

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 64px;
  padding: 0;
  padding-bottom: 16px;

  ${MEDIA.TABLET`
    display: block;
  `}
`;

export const SmallHeading = styled.h4`
  font: 400 24px/1.2 ${barlowFont};
  letter-spacing: ${barlowSpacing};
  margin-bottom: 24px;
  color: ${medium};

  ${MEDIA.TABLET`
    margin-left: auto;
    margin-right: auto;
    max-width: ${maxCardWidth};
  `}
`;
