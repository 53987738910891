import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Head from '../../common/head';
import Layout from '../../layout/layout';
import FooterTile from '../../common/footerTile';
import Footer from '../../common/footer';
import Container from '../../structure/container';
import Section from '../../structure/section';
import BlogHero from '../hero';
import Pager from '../pager';
import ArticlesList from '../articlesList';
import CategoryTeaser from './teaser';

const CategoryLayout = ({ data, pageContext }) => {
  const tags = data.tags.edges.map(({ node }) => node.frontmatter.slug);
  const latestArticle = data.latestArticle.edges[0];
  const isFirstPage = !pageContext.skip;
  const articles = isFirstPage
    ? data.articles.edges.slice(1)
    : data.articles.edges;
  const { name, slug } = data.category.frontmatter;
  const campaign = `category-${slug.toLowerCase()}`;

  return (
    <Layout>
      <Head pageTitle={name} />
      <Section hasNoPadding>
        <Container>
          <BlogHero
            featuredArticle={isFirstPage && latestArticle.node}
            campaign={campaign}
            breadcrumbs={[
              { label: 'Beyond', path: '/beyond/' },
              { label: name, path: `/${slug.toLowerCase()}/` },
            ]}
          >
            <CategoryTeaser {...data.category.frontmatter} tags={tags} />
          </BlogHero>
        </Container>
      </Section>
      <Section hasNoPadding>
        <Container>
          <ArticlesList
            title={isFirstPage ? 'More Articles' : ''}
            articles={articles}
          />
          <Pager {...pageContext} />
        </Container>
      </Section>
      <FooterTile campaign={campaign} />
      <Footer />
    </Layout>
  );
};

CategoryLayout.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query BlogPostsByCategory($category: String, $skip: Int!, $limit: Int!) {
    category: markdownRemark(
      fileAbsolutePath: { regex: "/(categories)/" }
      frontmatter: { slug: { eq: $category } }
    ) {
      ...CategoryTeaser
    }
    tags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(tags)/" }
        frontmatter: { category: { eq: $category } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
          }
        }
      }
    }
    latestArticle: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(posts)/" }
        frontmatter: { categories: { eq: $category } }
      }
      limit: 1
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(posts)/" }
        frontmatter: { categories: { eq: $category } }
      }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
  }
`;

export default CategoryLayout;
