import React from 'react';
import PropTypes from 'prop-types';
import {
  LandingContainer,
  ColumnHalf,
  VpWrapper,
  ArtworkWrapper,
  QrWrapper,
  LogoWrapper,
  Title,
  Subtitle,
  Paragraph,
} from './freeTrial.css';
import GlobalStyle from 'global.css.js';
import qrCode from '../../images/landings/qr-code.png';
import logo from '../../images/logo-main.png';
import { Helmet } from 'react-helmet';

const FreeTrialLayout = ({ heading, subheading, closing, hasqr }) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <GlobalStyle />
    <LandingContainer>
      <ColumnHalf>
        <VpWrapper>
          <LogoWrapper>
            <img src={logo} alt="30 Day Fitness Logo" />
          </LogoWrapper>
          <Title>
            Crush your workout goals <span>{heading}!</span>
          </Title>

          <Paragraph>
            Before working on those <strong>chiseled abs</strong>,
            <strong>perky booty</strong>, or <strong>head-turning</strong>
            <strong>beach bod</strong>, you need to start your{' '}
            <strong>30 Day Fitness {subheading}</strong>!
          </Paragraph>
          {hasqr && (
            <QrWrapper>
              <Subtitle>
                Scan the QR code and
                <br />
                <span>start working out!</span>
              </Subtitle>
              <img src={qrCode} alt="qr code" />
            </QrWrapper>
          )}
          <Paragraph>
            Or do it manually: If the 30 Day Fitness app is{' '}
            <strong>already installed</strong>, simply open the app and follow
            the instructions. <strong>If it’s not installed</strong>, re-open
            the email from your <strong>mobile or tablet</strong>, and click
            Start My Free Trial. You’ll be taken straight to the download page.
            Couldn’t be simpler!
          </Paragraph>
          <Paragraph>
            Remember: the sooner you take advantage of{' '}
            <strong>{closing}</strong>, the sooner you get your{' '}
            <strong>summer body</strong>!
          </Paragraph>
        </VpWrapper>
      </ColumnHalf>
      <ColumnHalf>
        <ArtworkWrapper></ArtworkWrapper>
      </ColumnHalf>
    </LandingContainer>
  </>
);

FreeTrialLayout.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  closing: PropTypes.string,
  hasqr: PropTypes.bool,
};

export default FreeTrialLayout;
