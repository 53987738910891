import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  backgroundPrimary,
  darkest,
  dark,
  barlowFont,
  firaFont,
  maxCardWidth,
  lightest,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { hoverOpacityEffect } from '../../styles/effects.css';
import { BadgeWrapper } from './category/badge.css';

export const ImageWrapper = styled(Link)`
  height: 200px;
  width: 100%;
  > div {
    height: 100%;
    ${hoverOpacityEffect}
  }
`;

export const ContentWrapper = styled.div`
  padding: 32px 24px;
`;

export const Title = styled(Link)`
  ${hoverOpacityEffect};
  display: block;
  font-family: ${barlowFont};
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1px;
  color: ${darkest};
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Copy = styled.p`
  font-family: ${firaFont};
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: ${dark};
`;

export const CardWrapper = styled.div`
  align-self: start;
  background: ${backgroundPrimary};
  border: 2px solid ${lightest};
  border-radius: 16px;
  overflow: hidden;
  max-width: ${maxCardWidth};
  transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1),
    box-shadow 300ms linear;
  &:hover {
    box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
    border: 2px solid transparent;
    transform: translateY(-8px);
  }
  ${MEDIA.TABLET`
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    &:last-child {
      margin-bottom: 0;
    }
  `};
  ${({ isFeatured }) =>
    isFeatured &&
    MEDIA.MIN_DESKTOP`
      ${ImageWrapper} {
        height: 300px;
      }
      ${BadgeWrapper} {
        font-size: 20px;
      }
      ${Title} {
        font-size: 32px;
        line-height: 40px;
        margin-top: 24px;
        margin-bottom: 24px;
      }
  `}
`;
