import React from 'react';
import PropTypes from 'prop-types';
import { BadgeWrapper } from '../category/badge.css';
import useTag from '../../../hooks/use-tag';

const TagBadge = ({ tag }) => {
  const tagData = useTag(tag);
  return (
    <>
      {tagData && <BadgeWrapper to={tagData.url}>{tagData.name}</BadgeWrapper>}
    </>
  );
};

TagBadge.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default TagBadge;
