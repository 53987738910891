import React from 'react';
import ReactDOM from 'react-dom';

import { Backdrop, Modal } from '../modal.css';
import { Button, Paragraph, Title } from './errorModal.css';

const ErrorModal = ({ message, onClose }) => {
  return ReactDOM.createPortal(
    <Backdrop onClick={onClose}>
      <Modal>
        <Title>Oh no!</Title>
        <Paragraph>{message}</Paragraph>
        <Paragraph>
          If the problem persists, contact us at{' '}
          <a href="mailto:30dayfitness@bendingspoons.com">
            30dayfitness@bendingspoons.com
          </a>
        </Paragraph>
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </Modal>
    </Backdrop>,
    document.body
  );
};

export default ErrorModal;
