import styled from 'styled-components';
import { white, dark } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const SocialWrapper = styled.div`
  display: flex;
  margin: 24px auto 0;
  button {
    width: auto;
    margin-right: 16px;
    width: 100%;
    max-width: 200px;
    &:last-child {
      margin-right: 0;
    }
    ${MEDIA.PHONE`
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    `};
  }
  ${MEDIA.PHONE`
    flex-direction: column;
  `};
`;

export const ShareButton = styled.div`
  z-index: 5;
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  line-height: 24px;
  font-weight: 700;
  border-radius: 9px;
  color: ${white};
  text-decoration: none;
  &::after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 80%;
    height: 60%;
    top: 40%;
    left: 10%;
    background: ${dark};
    opacity: 0.8;
    filter: blur(15px);
    border-radius: 30px;
  }
  &.facebook,
  &.facebook::after {
    background: #1778f2;
  }
  &.twitter,
  &.twitter::after {
    background: #1da1f2;
  }
  &.whatsapp,
  &.whatsapp::after {
    background: #25d366;
  }
  ${MEDIA.PHONE`
    width: 100%;
    margin: 0 auto 24px;
  `};
`;
