import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  NavWrapper,
  LogoWraper,
  ListWrapper,
  ButtonWrapper,
  DownloadButton,
  TrialParagraph,
} from './offCanvasNav.css';
import logo from '../../images/logo-main.png';
import useAdjustLink from '../../hooks/use-adjust-link';
import HeaderLinks from './header/links';

const OffCanvasNav = ({ isOpen, links }) => {
  const ctaRef = useRef();
  const url = useAdjustLink({ campaign: 'navbar', store: 'apple' }, ctaRef);
  return (
    <NavWrapper>
      <LogoWraper isOpen={isOpen}>
        <img src={logo} alt="30 Day Fitness Logo" />
      </LogoWraper>
      <ListWrapper isOpen={isOpen}>
        <HeaderLinks links={links} />
      </ListWrapper>
      <ButtonWrapper isOpen={isOpen}>
        <DownloadButton
          href={url}
          ref={ctaRef}
          target="_blank"
          rel="nofollow noreferrer"
        >
          Install now
        </DownloadButton>
        <TrialParagraph>Free trial available for new members</TrialParagraph>
      </ButtonWrapper>
    </NavWrapper>
  );
};

OffCanvasNav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  links: PropTypes.array.isRequired,
};

export default OffCanvasNav;
