import { css } from 'styled-components';
import MEDIA from '../helpers/mediaTemplates';

// An even simpler way of using the media templates, with the added
// benefit of grouping similar values together for easier understanding
//
// What's more readable? This
//
//  padding: 24px;
//  ${MEDIA.TABLET`
//    padding: 12px;
//  `}
//  ${MEDIA.PHONE`
//    padding: 8px;
//  `}
//
// Or this?
//
//  ${responsiveMixin(
//    DEFAULT: 'padding: 24px',
//    TABLET: 'padding: 12px',
//    PHONE: 'padding: 8px',
//  )}
//
// I believe the latter
export default function responsiveMixin(breakpoints) {
  return Object.keys(breakpoints).reduce((style, breakpoint) => {
    if (breakpoint == 'DEFAULT') {
      return css`
        ${style}
        ${breakpoints[breakpoint]}
      `;
    }
    return css`
      ${style}
      ${MEDIA[breakpoint]`
          ${breakpoints[breakpoint]}
        `}
    `;
  }, '');
}
