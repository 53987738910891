import React from 'react';
import PropTypes from 'prop-types';
import ReviewItem from './reviews/item';
import Container from '../structure/container';
import Section from '../structure/section';
import {
  SectionWrapper,
  RatingsWrapper,
  ContentWrapper,
  ContentTitle,
  ContentParagraph,
  MainButton,
  ReviewsWrapper,
} from './reviews.css';
import Ratings from '../../images/ratings.png';

const Reviews = ({ items }) => (
  <Section>
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          <ContentTitle isCenteredMobile>
            We Believe in our Community
          </ContentTitle>
          <RatingsWrapper>
            <img src={Ratings} alt="4.5 out of 5 stars" />
            App Store Rating
          </RatingsWrapper>
          <ContentParagraph isCenteredMobile>
            Our community comes first! We really value your feedback and
            suggestions, thats why our app has such a high rating and stays
            always updated.
          </ContentParagraph>
          <MainButton href="https://30dayfitness.uservoice.com/" target="blank">
            Give Us Your Suggestions
          </MainButton>
        </ContentWrapper>
        <ReviewsWrapper>
          {items.slice(0, 3).map((item, i) => (
            <ReviewItem {...item} key={i} />
          ))}
        </ReviewsWrapper>
      </SectionWrapper>
    </Container>
  </Section>
);

Reviews.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Reviews;
