import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InputGroup, InputGroupIcon } from '../ui/inputGroup.css';
import { Input } from '../ui/input.css';

import AtIcon from '../../../images/onboarding/fira-sans-at.svg';
import { PrimaryButton } from '../ui/button.css';
import { FormWrapper, Title, Subtitle } from './loginForm.css';

const LoginForm = ({ readOnly, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [hasError, setHasError] = useState('');

  /**
   * @param {import('react').SyntheticEvent<
   *   HTMLInputElement,
   *   import('react').ChangeEvent<HTMLInputElement>
   * >} e
   */
  const onEmailChange = (e) => {
    /** @type {HTMLInputElement} */
    const target = e.target;
    setValid(target.validity.valid && target.value);
    setEmail(target.value);
  };

  const onEmailFocus = () => {
    setHasError(false);
  };

  const onEmailBlur = () => {
    setHasError(!valid);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (valid) {
      onSubmit(email);
    }
  };

  return (
    <FormWrapper onSubmit={onFormSubmit}>
      <Title>Password-Free Login</Title>
      <Subtitle>
        Login with your email. You will receive a unique, temporary link to
        manage your subscription.
      </Subtitle>
      <InputGroup withIcon>
        <InputGroupIcon hasError={hasError}>
          <AtIcon />
        </InputGroupIcon>
        <Input
          type="email"
          placeholder="Email address"
          readOnly={readOnly}
          hasError={hasError}
          onChange={onEmailChange}
          onFocus={onEmailFocus}
          onBlur={onEmailBlur}
        />
      </InputGroup>

      <PrimaryButton disabled={readOnly} looksDisabled={!valid}>
        Send magic link
      </PrimaryButton>
    </FormWrapper>
  );
};

LoginForm.propTypes = {
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default LoginForm;
