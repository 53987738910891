import React, { useCallback, useEffect, useState } from 'react';
import { AnimatePresence, m } from 'framer-motion';
import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';

import useValueReference from '../../hooks/use-value-reference';
import slugify from '../../helpers/slugify';

import Section from '../structure/section';
import Accordion from './accordion';
import {
  FAQsContainer,
  FAQsCopy,
  FAQsTitle,
  FAQsAnchor,
  FAQsSmallHeading,
  FAQsContact,
  PlatformButtons,
  PlatformButton,
} from './layout.css';
import { LeadParagraph } from '../../styles/typography.css';

import AppleLogo from '../../images/buttons/apple-logo.svg';
import PlayStoreLogo from '../../images/buttons/play-store-logo.svg';
import WebIcon from '../../images/buttons/web-icon.svg';

const FAQsLayout = ({ allFAQs }) => {
  const location = useLocation();

  const platformsOrder = ['apple', 'android', 'web'];
  const platformsData = {
    apple: {
      label: 'Apple',
      Icon: AppleLogo,
    },
    android: {
      label: 'Android',
      Icon: PlayStoreLogo,
    },
    web: {
      label: 'Website',
      Icon: WebIcon,
    },
  };

  const getLocationInfo = useCallback(() => {
    const hash = location.hash.slice(1);

    const normalizePlatform = (platform) => {
      if (platformsOrder.indexOf(platform) != -1) {
        return platform;
      }
      return platformsOrder[0];
    };

    const firstDashIndex = hash.indexOf('-');
    if (firstDashIndex != -1) {
      return {
        platform: normalizePlatform(hash.slice(0, firstDashIndex)),
        question: hash,
      };
    }

    return { platform: normalizePlatform(hash), question: '' };
  }, [location]);

  const [currentPlatform, setCurrentPlatform] = useState(platformsOrder[0]);
  const [currentQuestion, setCurrentQuestion] = useState('');

  useEffect(() => {
    const { platform, question } = getLocationInfo();
    setCurrentPlatform(platform);
    setCurrentQuestion(question);
  }, [getLocationInfo]);

  const getCurrentPlatform = useValueReference(currentPlatform);

  const [previousPlatform, setPreviousPlatform] = useState('');
  useEffect(() => {
    setPreviousPlatform(currentPlatform);
  }, [currentPlatform]);

  return (
    <FAQsContainer>
      <FAQsTitle>Frequently Asked Questions</FAQsTitle>

      <Section style={{ textAlign: 'center' }}>
        <LeadParagraph>Where did you get your subscription?</LeadParagraph>
        <PlatformButtons>
          {platformsOrder.map((key) => {
            const { label, Icon } = platformsData[key];
            const isPressed = key == currentPlatform;
            return (
              <PlatformButton
                type="button"
                style={{ opacity: key == currentPlatform ? 1 : 0.6 }}
                onClick={() => {
                  navigate(`#${key}`);
                  setCurrentPlatform(key);
                }}
                aria-pressed={isPressed}
                isPressed={isPressed}
                key={key}
              >
                <Icon aria-hidden="true" />
                <span>{label}</span>
              </PlatformButton>
            );
          })}
        </PlatformButtons>
      </Section>

      <Section hasNoPadding style={{ position: 'relative' }}>
        <AnimatePresence initial={false}>
          {platformsOrder.map((key) => {
            const displacement = 300;
            return key == currentPlatform ? (
              <m.div
                initial={() => ({
                  x:
                    Math.sign(
                      platformsOrder.indexOf(key) -
                        platformsOrder.indexOf(previousPlatform)
                    ) * displacement,
                  opacity: 0,
                })}
                animate={{ x: 0, opacity: 1 }}
                exit={() => ({
                  position: 'absolute',
                  x:
                    Math.sign(
                      platformsOrder.indexOf(key) -
                        platformsOrder.indexOf(getCurrentPlatform())
                    ) * displacement,
                  opacity: 0,
                })}
                key={key}
              >
                {allFAQs.edges
                  .filter((edge) => edge.node.frontmatter.platform == key)
                  .map((FAQ) => {
                    const { question, platform } = FAQ.node.frontmatter;
                    const { answer } = FAQ.node;
                    const slug = slugify(`${platform}-${question}`);
                    return (
                      <FAQsAnchor id={slug} key={slug}>
                        <Accordion
                          isOpen={slug == currentQuestion}
                          onToggle={(open) =>
                            setCurrentQuestion(open ? slug : null)
                          }
                          title={question}
                        >
                          <FAQsCopy
                            dangerouslySetInnerHTML={{ __html: answer }}
                          />
                        </Accordion>
                      </FAQsAnchor>
                    );
                  })}
              </m.div>
            ) : null;
          })}
        </AnimatePresence>
      </Section>
      <Section hasNoPadding>
        <FAQsSmallHeading>
          Didn&apos;t find what you were looking for?
        </FAQsSmallHeading>
        <FAQsContact href="mailto:30dayfitness@bendingspoons.com">
          Contact Us
        </FAQsContact>
      </Section>
    </FAQsContainer>
  );
};

FAQsLayout.propTypes = {
  allFAQs: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            question: PropTypes.string,
          }),
          answer: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export default FAQsLayout;

export const query = graphql`
  fragment allFAQs on Query {
    allFAQs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//faqs//" } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            question
            platform
          }
          answer: html
        }
      }
    }
  }
`;
