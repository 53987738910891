import React, { useRef } from 'react';

import useAdjustLink from '../hooks/use-adjust-link';
import MoreLayout from '../components/landings/more';
import MoreHero from '../components/landings/more/hero';
import {
  MoreCopyButton,
  MoreCopy,
} from '../components/landings/more/shared.css';
import { MoreContainer, MoreSection } from '../components/landings/more.css';

const GetFit15LongHero = () => {
  const ctaRef = useRef();
  const href = useAdjustLink(
    {
      campaign: 'badge-article-cta-download',
      store: 'apple',
    },
    ctaRef
  );
  return (
    <MoreLayout isNarrow footerStyle="tile">
      <MoreSection hasHero>
        <MoreContainer isNarrow>
          <MoreHero />
          <MoreCopy>
            <p>
              Whether you&apos;re a complete beginner or a regular workout
              enthusiast,
              <b> starting a workout routine is often hard </b> and
              <b> overwhelming</b>. While it&apos;s common to feel this way,
              there&apos;s a simple solution—the 30 Day Fitness app.
            </p>
            <p>
              Are 15-minute workouts effective, though? This is a common
              question and it&apos;s okay to be skeptical. Below, we&apos;ll go
              over some typical concerns and how 30 Day Fitness addresses them.
            </p>
            <h2>Can Short Workouts Be Effective?</h2>
            <p>
              Many people use to think that they need to spend an hour in the
              gym using weights and that exercises without equipment
              wouldn&apos;t be enough to build the muscle they want. But, after
              discovering the 30 Day Fitness app, their perception radically
              transformed, along with their body.
            </p>
            <h3>The 30 Day Fitness Approach: Consistency Is Key</h3>
            <p>
              Scientific studies prove that it&apos;s possible to achieve
              extraordinary results with a 15-minute workout if you stick to the
              plan. The trick with shortening your workouts is to exercise
              smarter instead of longer.
            </p>
            <p>
              You can get gym-quality results working out 3-4 times a week for
              15-20 minutes each session from home. 30 Day Fitness will expertly
              guide you through each session, so you don&apos;t have to worry
              about working it out for yourself. This is a game-changer because
              we will teach you the easiest way to stick to a workout routine –
              and consistency is the only way you&apos;ll see results.
            </p>
            <h2>Personal Guidance</h2>
            <p>
              People of all fitness levels can benefit from personal training.
              This is especially helpful for beginners who don&apos;t have any
              idea what they&apos;re doing yet as it tells them exactly what to
              work on, and when.
            </p>
            <p>
              Using an app is convenient, but how does it compare to training in
              person with an expert?
            </p>
            <h3>
              The 30 Day Fitness Approach: Professionally Curated Routines
            </h3>
            <p>
              While you may not be in the same physical room as a trainer, you
              might as well be. Our app contains pre-made workouts that were
              created by real-life, professional personal trainers.
            </p>
            <p>
              This means you don&apos;t have to worry about putting exercises
              together and hoping they work for you. Our professionals have
              already done this and the app tells you exactly what to do, for
              how long, and in what order.
            </p>
            <p>
              Still worried about finding the right routine for you? We&apos;ve
              got you covered. Workouts are filtered by target area, skill
              level, and length. These parameters let you fully personalize the
              experience, leaving you with no doubt that what you&apos;re doing
              brings results.
            </p>
            <MoreCopyButton ref={ctaRef} href={href} rel="nofollow">
              Download the App
            </MoreCopyButton>
            <h2>Motivation is a Struggle</h2>
            <p>
              Many people have no motivation to workout at times even when they
              are going to a real gym regularly. At least with a gym setting,
              you&apos;re around others and can find support and inspiration
              from being in that environment.
            </p>
            <p>
              With a fitness app, you&apos;re mostly on your own. This certainly
              has benefits, but how do you find motivation when you&apos;re all
              alone and the couch is right there?
            </p>
            <h3>The 30 Day Fitness Approach: Track Your Goals</h3>
            <p>
              30 Day Fitness enables you to create weekly plans and then keep
              track of what you do and how you progress. You&apos;ll be
              surprised how motivated you&apos;ll be to continue once you check
              off those first couple of milestones.
            </p>
            <p>
              If you want a true motivation jump-start, consider participating
              in one of our many 30-day challenges. You begin by choosing a
              challenge based on your goals, like burning belly fat, toning your
              arms, strengthening your core, etc. Then, we provide you with 30
              straight days of workouts that help you chase that exact goal.
              You&apos;ll have access to the first day only, with subsequent
              days unlocking as you complete each one. Upon reaching the final
              day, you&apos;ll feel accomplished and ready to take on the next
              challenge!
            </p>
            <p>Three final tips to kickstart your fitness journey:</p>
            <ol>
              <li>
                <span>Evaluate where you waste time. </span>
                Do you spend two hours on social media each evening? Binge-watch
                Netflix shows? Most people can find 15 minutes every day to
                spend on something worthwhile like exercise. It&apos;s just a
                question of prioritizing how you spend your time.
              </li>
              <li>
                <span>Get up 15 minutes earlier. </span>
                If you evaluated where you waste time and you honestly
                don&apos;t have even 15 minutes in your day where you can fit in
                your workout, then set your alarm for 15 minutes earlier. The
                great thing about this is that because you&apos;re working out
                from home with an app, 15 minutes is all it takes.
              </li>
              <li>
                <span>
                  Ensure that your workout fits your goals and fitness level.{' '}
                </span>
                It&apos;s easy to see that insane HIIT workout on Instagram (and
                all the super-fast results the creator promises) and want to
                give it a go. But if you rush straight in at a level that&apos;s
                too difficult, you&apos;ll end up either injuring yourself or
                being totally put off because it will feel way too hard.
              </li>
            </ol>
          </MoreCopy>
        </MoreContainer>
      </MoreSection>
    </MoreLayout>
  );
};

export default GetFit15LongHero;
