import React from 'react';
import PropTypes from 'prop-types';
import BlogCard from './card';
import { GridWrapper, SmallHeading } from './articlesList.css';

const ArticlesList = ({ title, articles }) => (
  <>
    {title && <SmallHeading>{title}</SmallHeading>}
    <GridWrapper>
      {articles.map((edge) => (
        <BlogCard {...edge.node} key={edge.node.frontmatter.slug} />
      ))}
    </GridWrapper>
  </>
);

ArticlesList.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array.isRequired,
};

export default ArticlesList;
