import React from 'react';
import PropTypes from 'prop-types';
import Container from '../structure/container';
import ExercisesGallery from './exercises/gallery';
import {
  ExerciseWrapper,
  ExerciseContent,
  ExerciseTitle,
  ExerciseParagraph,
  ButtonWrapper,
} from './exercises.css';
import StoreButtonApple from '../common/store-button/apple';
import StoreButtonGoogle from '../common/store-button/google';

const Exercises = ({ title, paragraph, workouts }) => (
  <ExerciseWrapper hasGradient>
    <Container isSmall>
      <ExerciseContent>
        <ExerciseTitle
          isCenteredMobile
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <ExerciseParagraph isCenteredMobile>{paragraph}</ExerciseParagraph>
        <ButtonWrapper>
          <StoreButtonApple campaign="badge-home-exercises" hasMargin />
          <StoreButtonGoogle campaign="badge-home-exercises" />
        </ButtonWrapper>
      </ExerciseContent>
    </Container>
    <ExercisesGallery workouts={workouts} />
  </ExerciseWrapper>
);

Exercises.propTypes = {
  title: PropTypes.node.isRequired,
  paragraph: PropTypes.node.isRequired,
  workouts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Exercises;
