import styled, { css } from 'styled-components';
import { white, buttonGradient, darkest, lightest } from '../constants/theme';
import { firaSans } from './typography.css';

/**
 * INDEPENT STYLES
 */

// Base button styles (border:none is quite opinionated)
export const buttonReset = css`
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  text-align: center;
`;

// The outer properties
export const buttonBlock = ({ full = false, pill = false } = {}) => css`
  display: block;
  width: ${full ? '100%' : 'fit-content'};
  ${full || 'max-width: 18em;'}
  margin-left: auto;
  margin-right: auto;
  border-radius: ${pill ? '100px' : '8px'};
`;

// The inner properties
export const buttonContent = ({ size = 'md', uppercase = false }) => {
  const sizes = {
    xs: css`
      padding: ${uppercase ? '8px' : '6px'} 16px 8px;
      font-size: 16px;
      line-height: 19px;
    `,
    sm: css`
      padding: ${uppercase ? '12px' : '10px'} 24px 12px;
      font-size: 16px;
      line-height: 19px;
    `,
    md: css`
      padding: ${uppercase ? '12px' : '10px'} 48px 12px;
      font-size: 17px;
      line-height: 21px;
    `,
    lg: css`
      padding: ${uppercase ? '17px' : '15px'} 24px 17px;
      font-size: 21px;
      line-height: 24px;
    `,
    xl: css`
      padding: ${uppercase ? '17px' : '15px'} 48px 17px;
      font-size: 21px;
      line-height: 24px;
    `,
  };
  return css`
    ${sizes[size] || size}
    ${uppercase && 'text-transform: uppercase;'}
  `;
};

/**
 * PRE-PACKAGED MIXINS
 */

/**
 * @param {string} color The button color
 * @param {string} background The button background
 * @param {string} additionalStyles Optional styles
 * @param {{size: 'sm'|'md'|'lg'|'xl'|string, pill: bool, full: bool, uppercase: bool}} options An additional list of options
 */
export const buttonMixin = (
  color,
  background,
  additionalStyles,
  options = {}
) => {
  const {
    size = 'md',
    pill = false,
    full = false,
    uppercase = false,
  } = options;

  return css`
    ${buttonReset}
    ${buttonBlock({ full, pill })}
    ${buttonContent({ size, uppercase })}

    color: ${color};
    background: ${background};
    ${additionalStyles}
  `;
};

export const primaryButton = buttonMixin.bind(
  null,
  white,
  buttonGradient,
  firaSans(500)
);
export const lightButton = buttonMixin.bind(
  null,
  darkest,
  lightest,
  firaSans(500)
);

/**
 * COMPONENTS
 */

// Use this to reduce the amount of repetition in the final output
export const BaseButton = styled.button`
  ${buttonReset}
`;
