import { useEffect, useState } from 'react';

// Global object to which the hook instances can subscribe
// A simpler version of the observer pattern, with only one channel
const trigger = {
  observers: [],
  on(cb) {
    this.observers = [...this.observers, cb];
  },
  off(cb) {
    this.observers = this.observers.filter((_cb) => _cb !== cb);
  },
  fire() {
    this.observers.forEach((cb) => cb());
  },
};

const useOptimize = (cb, deps = []) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const forceUpdate = () => setCounter((i) => i + 1);
    trigger.on(forceUpdate);
    return () => trigger.off(forceUpdate);
  }, []);
  useEffect(cb, [counter, ...deps]);
};

export default useOptimize;

export const onOptimizeActivation = () => trigger.fire();
