import styled from 'styled-components';
import { SecondaryTitle, MainParagraph } from '../../styles/typography.css';
import { RowWrapper } from '../structure/row.css';
import { ratingsOrange, firaSpacing } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import { primaryButton } from '../../styles/buttons.css';

export const SectionWrapper = styled(RowWrapper)`
  align-items: flex-start;
  ${MEDIA.TABLET`
    margin: 0 auto;
 `};
`;

export const RatingsWrapper = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: ${firaSpacing};
  color: ${ratingsOrange};
  margin-bottom: 24px;
  ${MEDIA.TABLET`
    margin: 0 auto;
    flex-direction: column;
    margin-bottom: 24px;
  `}
  img {
    max-width: 140px;
    margin-right: 16px;
    ${MEDIA.TABLET`
      margin-right: 0;
      margin-bottom: 16px;
    `}
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-right: 24px;
  max-width: 420px;
  ${MEDIA.TABLET`
    max-width: 540px;
    margin: 0 auto 40px;
  `}
`;

export const ContentTitle = styled(SecondaryTitle)`
  width: 100%;
`;

export const ContentParagraph = styled(MainParagraph)`
  width: 100%;
  margin-bottom: 40px;
`;

export const MainButton = styled.a`
  ${primaryButton({ size: 'xl' })}
  ${MEDIA.TABLET`
    ${primaryButton({ size: 'lg', full: true })}
  `}
`;

export const ReviewsWrapper = styled.div`
  max-width: 540px;
  padding-bottom: 16px;
  ${MEDIA.TABLET`
    margin: 0 auto;
  `}
`;
