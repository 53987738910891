import React from 'react';
import StoreButtonApple from '../../common/store-button/apple';
import StoreButtonGoogle from '../../common/store-button/google';
import {
  HeroWrapper,
  HeroContent,
  HeroContentTitle,
  CheckWrapper,
  CheckWrapperSingle,
  CheckTitle,
  CheckIcon,
  StoreButtonWrapper,
  // StoreButtonWrapperSingle,
} from './hero.css';

const Hero = () => (
  <HeroWrapper>
    <HeroContent>
      <HeroContentTitle>
        The Fitness App That Will Change Your Life
      </HeroContentTitle>
      <CheckWrapper>
        <CheckWrapperSingle>
          <CheckIcon />
          <CheckTitle>15-minute workouts without going to the gym</CheckTitle>
        </CheckWrapperSingle>
        <CheckWrapperSingle>
          <CheckIcon />
          <CheckTitle>Personalized fitness plans and challenges</CheckTitle>
        </CheckWrapperSingle>
        <CheckWrapperSingle>
          <CheckIcon />
          <CheckTitle>More than 400 workouts and 92 exercises</CheckTitle>
        </CheckWrapperSingle>
      </CheckWrapper>
      <StoreButtonWrapper>
        <StoreButtonApple campaign="badge-article-apple" hasMargin />
        <StoreButtonGoogle campaign="badge-article-googleplay" />
      </StoreButtonWrapper>
    </HeroContent>
  </HeroWrapper>
);

export default Hero;
