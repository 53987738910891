import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import { getUserUUID } from '../helpers/trackingLink';
import PaymentProvider from '../components/monetization/flow/payment/paymentProvider';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    open: false,
  });
  const showModal = () => setState({ open: true, ...state });
  const hideModal = () => setState({ open: false, ...state });

  useEffect(async () => {
    const uuid = await getUserUUID();
    window.webUUID = uuid;
  }, []);

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          oracle {
            url
            website
          }
          stripe {
            key
            version
          }
        }
      }
    }
  `);
  const { stripe, oracle } = site.siteMetadata;

  return (
    <PaymentProvider stripe={stripe} oracle={oracle}>
      <Provider value={{ ...state, showModal, hideModal }}>{children}</Provider>
    </PaymentProvider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
