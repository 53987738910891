import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './shared.css';
import AppleLight from '../../../images/buttons/apple-white.svg';
import AppleDark from '../../../images/buttons/apple-dark.svg';
import useAdjustLink from '../../../hooks/use-adjust-link';

const StoreButtonApple = ({ isDark, hasMargin, campaign }) => {
  const ctaRef = useRef();
  const url = useAdjustLink({ campaign, store: 'apple' }, ctaRef);
  return (
    <ButtonWrapper
      marginRight={hasMargin}
      href={url}
      ref={ctaRef}
      className="app-store-badge"
    >
      {isDark ? <AppleDark /> : <AppleLight />}
    </ButtonWrapper>
  );
};

StoreButtonApple.propTypes = {
  hasMargin: PropTypes.bool,
  isDark: PropTypes.bool,
  campaign: PropTypes.string,
};

export default StoreButtonApple;
