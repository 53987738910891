import styled, { css } from 'styled-components';
import {
  barlowFont,
  barlowSpacing,
  darkest,
  fontSize,
  lightest,
  medium,
  shadowLight,
} from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';
import {
  barlowSemiCondensed,
  Copy,
  MainTitle,
} from '../../styles/typography.css';
import { BaseButton, primaryButton } from '../../styles/buttons.css';
import { ContainerWrapper } from '../structure/container.css';
import Anchor from './anchor';
import { AnchorLink } from './anchor.css';
import responsiveMixin from '../../styles/responsiveMixin';

export const FAQsContainer = styled(ContainerWrapper)`
  max-width: 782px;
`;

export const FAQsAnchor = styled(Anchor)`
  ${AnchorLink} {
    top: 24px;
    font: 400 24px/1.4 ${barlowFont};
    text-decoration: none;
  }
`;

export const FAQsTitle = styled(MainTitle)`
  text-align: center;
  margin-bottom: 0;
  margin-top: 40px;
  ${MEDIA.TABLET`
    margin-top: 140px;
  `}
`;

export const PlatformButton = styled(BaseButton)`
  width: 100%;
  ${responsiveMixin({
    DEFAULT: css`
      padding: 16px;
      ${fontSize['1.5x']}
    `,
    TABLET: css`
      padding: 12px;
      ${fontSize['1.25x']}
    `,
  })}
  ${barlowSemiCondensed()}
  color: ${darkest};
  background: ${({ isPressed }) => (isPressed ? 'white' : 'transparent')};
  opacity: ${({ isPressed }) => (isPressed ? 1 : 0.6)};
  border: 2px solid ${lightest};
  ${({ isPressed }) => isPressed && `box-shadow: ${shadowLight};`}

  display: flex;
  justify-content: center;
  align-items: center;
  & * + * {
    margin-left: 12px;
  }
`;

export const PlatformButtons = styled.div`
  display: flex;
  ${responsiveMixin({
    MIN_PHONE: css`
      & ${PlatformButton} + ${PlatformButton} {
        border-left: 0;
      }
      & ${PlatformButton}:first-of-type {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
      & ${PlatformButton}:last-of-type {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    `,
    PHONE: css`
      flex-direction: column;
      & ${PlatformButton} + ${PlatformButton} {
        border-top: 0;
      }
      & ${PlatformButton}:first-of-type {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
      & ${PlatformButton}:last-of-type {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    `,
  })}
`;

export const FAQsCopy = styled(Copy)``;

export const FAQsSmallHeading = styled.h2`
  font: 400 20px/24px ${barlowFont};
  letter-spacing: ${barlowSpacing};
  margin-bottom: 24px;
  color: ${medium};
  text-align: center;
`;

export const FAQsContact = styled.a`
  ${primaryButton({ size: 'xl' })}
  margin-bottom: 16px;
  ${MEDIA.TABLET`
    ${primaryButton()}
  `}
`;
