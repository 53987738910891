import styled from 'styled-components';
import { fontSize } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import background from '../../../images/more/background.jpg';
import check from '../../../images/more/check.svg';
import { responsiveTypography } from '../../../styles/typography.css';

export const HeroWrapper = styled.div`
  background: url(${background}) center right;
  background-size: cover;
  padding: 48px 32px 64px;
  border-radius: 0px 0px 64px 64px;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    padding: 48px 24px;
    margin: 0 -24px 32px;
    width: 100vw;
    border-radius: 0px 0px 48px 48px;
  }
  @media screen and (max-width: 350px) {
    margin: 0 -8px 32px;
    padding: 48px 8px;
  }
`;

export const HeroContent = styled.div`
  max-width: 340px;
  @media screen and (max-width: 768px) {
    max-width: 240px;
  }
`;

export const HeroContentTitle = styled.h1`
  margin-bottom: 24px;
  ${responsiveTypography('Barlow', 800, {
    DEFAULT: fontSize['3x'],
  })}
  line-height: 1;
  color: white;
  text-transform: uppercase;
`;

export const CheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 260px;
`;

export const CheckWrapperSingle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const CheckTitle = styled.h4`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: white;
`;

export const CheckIcon = styled(check)`
  margin-right: 16px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

export const StoreButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  ${MEDIA.SMALL_PHONE`
    flex-direction: column;
    a + a {
      margin-top: 8px;
    }
  `}
`;
