import styled from 'styled-components';
import MEDIA from '../../../helpers/mediaTemplates';

export const ButtonWrapper = styled.a`
  box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
  border-radius: 9px;
  height: 48px;
  margin-right: ${({ marginRight }) => (marginRight ? '16px' : 0)};
  /* ${MEDIA.TABLET`
    margin-right: 0;
  `}; */
`;
