const merge = (oldSteps, operations) => {
  const newSteps = operations.map(({ op, ...args }) => {
    switch (op) {
      case 'copy':
        return oldSteps[args.from];
      case 'set':
        return args.step;
    }
  });
  return newSteps;
};

module.exports = merge;
