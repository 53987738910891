import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import Head from 'components/common/head';
import FooterTile from 'components/common/footerTile';
import Footer from 'components/common/footer';
import BlogArchive from 'components/blog/archive';
import Hero from 'components/home/hero';
import Feature from 'components/home/feature';
import Exercises from 'components/home/exercises';
import Reviews from 'components/home/reviews';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <Head
      pageTitleFull="Workouts at Home | 30 Day Fitness Challenge"
    />
    <Hero
      title={data.homeJson.title}
      paragraph={data.homeJson.content}
      image={data.homeJson.image.childImageSharp.fluid}
    />
    {data.homeJson.features.map((item, index) => (
      <Feature
        key={index}
        title={item.title}
        paragraph={item.copy}
        image={item.image.childImageSharp.fluid}
        isDarker={item.isDark}
        isReverse={item.isReverse}
        campaignName={item.campaignName}
      />
    ))}
    <Exercises
      title={data.homeJson.exercises.title}
      paragraph={data.homeJson.exercises.copy}
      workouts={data.homeJson.exercises.workouts}
    />
    <BlogArchive />
    <Reviews items={data.homeJson.reviews} />
    <FooterTile
      appleCampaign="badge-home-down"
      googleCampaign="google-badge-home-down"
    />
    <Footer />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content
      image {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      features {
        title
        copy
        isDark
        isReverse
        campaignName
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      exercises {
        title
        copy
        workouts {
          id
          src {
            childImageSharp {
              fluid(maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      reviews {
        name
        date
        copy
      }
    }
  }
`;
