import styled from 'styled-components';
import { StepSubtitle, StepTitle } from '../flow/steps/types/shared.css';

export const FormWrapper = styled.form`
  > * + * {
    margin-top: 16px;
  }
`;

export const Title = styled(StepTitle)`
  text-align: center;
`;
export const Subtitle = styled(StepSubtitle)`
  text-align: center;
  margin-bottom: 32px;
`;
