import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import MoreLayout from '../components/landings/more';
import Testimonial from '../components/landings/more/testimonial';
import { MoreSection, MoreContainer } from '../components/landings/more.css';
import {
  MoreCopy,
  MoreSubtitle,
  MoreTitle,
  MoreImageWrapper,
} from '../components/landings/more/shared.css';

const GetFit15 = ({ data }) => (
  <MoreLayout footerStyle="text">
    {/* Content Section */}
    <MoreSection>
      {/* Title */}
      <MoreContainer isNarrow>
        <MoreTitle>
          Get in shape with 15-minute workouts? With this app, it’s finally
          possible.
        </MoreTitle>
        <MoreSubtitle>
          How long does it take to get fit? With 30 Day Fitness, 15 minutes of
          exercise a day is enough!
        </MoreSubtitle>
      </MoreContainer>

      {/* Image */}
      <MoreContainer>
        <MoreImageWrapper>
          <GatsbyImage fluid={data.heroImage.childImageSharp.fluid} alt="" />
        </MoreImageWrapper>
      </MoreContainer>

      {/* Content */}
      <MoreContainer isNarrow>
        <MoreCopy>
          <p>
            Whether you’re a complete beginner or a regular workout enthusiast,
            <b> starting a workout routine is often hard </b> and
            <b> overwhelming</b>. While it’s common to feel this way, there’s a
            simple solution—the 30 Day Fitness app. So, is 15 minutes of
            exercise a day enough to get fit? It sure is! Let&apos;s find out
            how this is possible.
          </p>
          <ol>
            <li>
              <span>Follow professionally curated routines. </span>
              The app contains ready-to-go workouts that were created by
              real-life, professional personal trainers. This means you don’t
              have to worry about putting exercises together and hoping they
              work for you. The professionals have already done this, and the
              app tells you exactly what to do, for how long, and in what order.
            </li>
            <li>
              <span>Learn how to do the exercises. </span>
              Everyone learns differently, which is why 30 Day Fitness has
              covered all the bases for you. There are written, step-by-step
              instructions that are detailed and easy to understand. In
              addition, they provide video instructions that include someone
              doing the exercise slowly and with perfect form.
            </li>
            <li>
              <span>Set goals and track your progress. </span>
              30 Day Fitness enables you to create weekly plans and then keep
              track of what you do and how you progress. You’ll be surprised how
              motivated you’ll be to continue once you check off those first
              couple of milestones!
            </li>
            <li>
              <span>Repetition. </span>
              New studies are showing that having small 15-minute workouts
              throughout the day is just as beneficial as if you were to work
              out for an hour at a time. As long as you go in with the right
              attitude, you’ll see fantastic results from this comprehensive,
              professional app.
            </li>
          </ol>
        </MoreCopy>
      </MoreContainer>
    </MoreSection>

    {/* Testimonial Section */}
    <MoreSection>
      <Testimonial />
    </MoreSection>
  </MoreLayout>
);

GetFit15.propTypes = {
  data: PropTypes.shape({
    heroImage: PropTypes.object,
  }),
};

export default GetFit15;

export const query = graphql`
  query GetFit15 {
    heroImage: file(
      relativePath: { eq: "more/fitness-routine-hero-image.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 782) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
