import styled from 'styled-components';
import { darkest, barlowSpacing, barlowFont } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import {
  MainTitle,
  LeadParagraph,
  MainParagraph,
} from '../../../styles/typography.css';
import { BadgeWrapper } from './badge.css';

export const TeaserTitle = MainTitle;

export const TeaserLeadParagraph = LeadParagraph;

export const TeaserMainParagraph = styled(MainParagraph)`
  margin-bottom: 32px;
`;

export const TeaserTagsHeading = styled.h3`
  font: 400 24px/1.2 ${barlowFont};
  letter-spacing: ${barlowSpacing};
  margin-bottom: 16px;
  color: ${darkest};
  ${MEDIA.TABLET`
    text-align: center;
    font-size: 20px;
  `}
`;

export const TeaserTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  ${BadgeWrapper} {
    margin-bottom: 8px;
  }
  ${BadgeWrapper} + ${BadgeWrapper} {
    margin-left: 8px;
  }
  ${MEDIA.TABLET`
    justify-content: center;
  `}
`;
