import React from 'react';
import { FooterWrapper } from './footer.css';
import FooterSocial from './footer/social';
import FooterImprint from './footer/imprint';

const Footer = () => (
  <FooterWrapper>
    <FooterSocial />
    <FooterImprint />
  </FooterWrapper>
);

export default Footer;
