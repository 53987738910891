import { graphql, StaticQuery } from 'gatsby';
import React, { useRef } from 'react';
import useAdjustLink from '../../../../hooks/use-adjust-link';
import {
  DownloadCta,
  DownloadCtaContent,
  DownloadCtaTitle,
  DownloadCtaParagraph,
  DownloadCtaImage,
  DownloadCtaButton,
} from './tile.css';

const Tile = () => {
  const ctaRef = useRef();
  const storeLink = useAdjustLink(
    {
      campaign: 'badge-footer-cta-download',
      store: 'apple',
    },
    ctaRef
  );
  return (
    <DownloadCta>
      <DownloadCtaContent>
        <DownloadCtaTitle>Start Your Routine Today</DownloadCtaTitle>
        <DownloadCtaParagraph>
          Download the app
          <br />
          to get started
        </DownloadCtaParagraph>

        <DownloadCtaButton href={storeLink} ref={ctaRef} rel="nofollow">
          Get The App Now
        </DownloadCtaButton>
      </DownloadCtaContent>
      <StaticQuery
        query={graphql`
          {
            file(relativePath: { eq: "more/cta-background.png" }) {
              childImageSharp {
                fluid(maxWidth: 580) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={({ file }) => (
          <DownloadCtaImage fluid={file.childImageSharp.fluid} alt="" />
        )}
      />
    </DownloadCta>
  );
};

export default Tile;
