import styled from 'styled-components';
import { MainParagraph, SecondaryTitle } from '../../styles/typography.css';
import { RowWrapper } from '../structure/row.css';
import MEDIA from '../../helpers/mediaTemplates';

export const FeatureWrapper = styled(RowWrapper).attrs(
  ({ isReverse, ...props }) => ({
    isRowReverse: isReverse,
    isColumnReverse: true,
    ...props,
  })
)`
  ${MEDIA.DESKTOP`
    justify-content: space-evenly;
  `};
`;

export const FeatureContent = styled.div`
  width: 100%;
  max-width: 440px;
  ${MEDIA.WIDESCREEN`
    max-width: 400px;
  `}
  ${MEDIA.DESKTOP`
    max-width: 320px;
  `}
  ${MEDIA.TABLET`
    max-width: 335px;
  `}
`;

export const FeatureTitle = styled(SecondaryTitle)`
  margin-bottom: 24px;
`;
export const FeatureParagraph = styled(MainParagraph)`
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  ${MEDIA.TABLET`
    justify-content: space-between;
  `};
`;

export const FeatureArtwork = styled.div`
  transform: ${({ isReverse }) =>
    isReverse ? 'translateX(-60px)' : 'translateX(60px)'};
  max-width: 540px;
  width: 100%;
  height: auto;
  ${MEDIA.WIDESCREEN`
    max-width: 480px;
    transform: translateX(0);
  `};
  ${MEDIA.DESKTOP`
    max-width: 400px;
  `};
  ${MEDIA.TABLET`
    margin-bottom: 48px;
  `};
`;
