import React from 'react';
import GlobalStyle from 'global.css.js';
import AppProvider from './src/store/provider';
import Transition from './src/components/transition/transition';
import { onOptimizeActivation } from './src/hooks/use-optimize';

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import 'typeface-barlow-condensed';
import 'typeface-barlow-semi-condensed';
import 'typeface-fira-sans';

gsap.registerPlugin(ScrollToPlugin);

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions and Style
export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <Transition {...props}>{element}</Transition>
    </>
  );
};

// TODO: turn this into a standalone plugin
export const onRouteUpdate = async () => {
  // Optimize integration
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'optimize.activate',
      eventCallback: () => {
        onOptimizeActivation();
      },
    });
  } else {
    onOptimizeActivation();
  }
};
