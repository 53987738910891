import styled, { css } from 'styled-components';
import { dark, fontSize, light } from '../../../constants/theme';
import responsiveMixin from '../../../styles/responsiveMixin';
import { firaSans } from '../../../styles/typography.css';

export const inputBorderMixin = css`
  border: 1px solid ${light};
  border-radius: 16px;
`;

export const inputMixin = css`
  width: 100%;

  ${fontSize['1.125x']}
  ${firaSans(500)}
  color: ${dark};
  ::placeholder {
    color: ${light};
  }

  margin: 0;
  -webkit-appearance: none;
  background: transparent;

  ${responsiveMixin({
    DEFAULT: 'padding: 16px 24px;',
    TABLET: 'padding: 16px 16px;',
    PHONE: 'padding: 16px 8px;',
  })}
  ${inputBorderMixin}

  ${({ hasError }) =>
    hasError &&
    css`
      color: #eb1c26;
    `}
`;

export const Input = styled.input`
  ${inputMixin}
`;
