import React from 'react';
import PropTypes from 'prop-types';
import { SectionWrapper } from './section.css';

const Section = ({
  children,
  isDarker,
  hasGradient,
  hasNoPadding,
  ...rest
}) => (
  <SectionWrapper
    isDarker={isDarker}
    hasGradient={hasGradient}
    hasNoPadding={hasNoPadding}
    {...rest}
  >
    {children}
  </SectionWrapper>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  isSmall: PropTypes.bool,
  isDarker: PropTypes.bool,
  hasGradient: PropTypes.bool,
  hasNoPadding: PropTypes.bool,
};

export default Section;
