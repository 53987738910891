import { useState } from 'react';
import useOptimize from './use-optimize';
import {
  getAnonymousTrackingLink,
  getTrackingLink,
} from '../helpers/trackingLink';

const useAdjustLink = ({ campaign, store }, ctaRef) => {
  // Initial state
  const anonymousTrackingLink = getAnonymousTrackingLink(campaign, store);
  const [adjustLink, setAdjustLink] = useState(anonymousTrackingLink);

  // For our commodity, the optimize logic is embedded into the adjust hook
  useOptimize(async () => {
    if (!ctaRef || ctaRef.current) {
      // Helper variables
      let ctaHref = null;
      let newAdjustLink = null;

      // Helper function
      const getCtaHref = () => (ctaRef ? ctaRef.current.href : null);

      do {
        // The DOM might have changed since the SSR
        // Pass a ref to the CTA to opt into this double check
        ctaHref = getCtaHref();
        const ctaURL = ctaHref ? new URL(ctaHref) : null;

        // eslint-disable-next-line prettier/prettier
        const ctaCampaign = ctaURL ? ctaURL.searchParams.get('campaign') : campaign;

        newAdjustLink = await getTrackingLink(ctaCampaign || campaign, store);

        // The DOM might have changed while we were waiting for the tracking link
      } while (getCtaHref() != ctaHref);

      setAdjustLink(newAdjustLink);
    }
  }, [ctaRef?.current]);

  return adjustLink;
};

export default useAdjustLink;
