import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AccordionWrapper,
  AccordionHeading,
  AccordionTitle,
  AccordionToggle,
  AccordionContent,
  AccordionContentWrapper,
} from './accordion.css';

const Accordion = ({ isOpen, onToggle, title, children, ...rest }) => {
  const contentRef = useRef();
  const getHeight = (element) =>
    element ? window.getComputedStyle(element).height : 'auto';

  return (
    <AccordionWrapper {...rest}>
      <AccordionHeading>
        <AccordionTitle isOpen={isOpen} onClick={() => onToggle(!isOpen)}>
          {title}
        </AccordionTitle>
        <AccordionToggle
          type="button"
          aria-pressed={isOpen}
          aria-label={isOpen ? 'Close Accordion' : 'Open Accordion'}
          isOpen={isOpen}
          onClick={() => onToggle(!isOpen)}
        />
      </AccordionHeading>
      <AccordionContentWrapper
        style={{ '--height': getHeight(contentRef.current) }}
        isOpen={isOpen}
      >
        <AccordionContent ref={contentRef}>{children}</AccordionContent>
      </AccordionContentWrapper>
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Accordion;
