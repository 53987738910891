import styled from 'styled-components';
import { dark, medium, barlowFont } from '../../constants/theme';
import { primaryButton } from '../../styles/buttons.css';
import { MainParagraph } from '../../styles/typography.css';

export const NavWrapper = styled.nav`
  position: absolute;
  height: auto;
  display: flex;
  flex-direction: column;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  transform-style: preserve-3d;
  z-index: -1;
`;

export const ListWrapper = styled.ul`
  margin-bottom: 40px;
  margin-right: 32px;
  padding-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
  li {
    margin-bottom: 32px;
    opacity: 0;
    transform: translateX(-150px);
    transition: transform 0.4s, opacity 0.4s;
    ${({ isOpen }) => {
      if (isOpen) {
        return `
          opacity: 1;
          transform: translateX(0);
      `;
      }
      return;
    }}
    &:nth-child(2) {
      transition-delay: 0.04s;
    }
    &:nth-child(3) {
      transition-delay: 0.08s;
    }
    &:nth-child(4) {
      transition-delay: 0.16s;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      font-family: ${barlowFont};
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;
      color: ${dark};
      text-decoration: none;
    }
  }
`;

export const LogoWraper = styled.div`
  max-width: 128px;
  margin-bottom: 48px;
  opacity: 0;
  transform: translateX(-150px);
  transition: transform 0.4s, opacity 0.4s;
  ${({ isOpen }) => {
    if (isOpen) {
      return `
          opacity: 1;
          transform: translateX(0);
      `;
    }
    return;
  }}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateX(-150px);
  transition: transform 0.4s, opacity 0.4s;
  transition-delay: 0.2s;
  ${({ isOpen }) => {
    if (isOpen) {
      return `
          opacity: 1;
          transform: translateX(0);
      `;
    }
    return;
  }}
`;

export const DownloadButton = styled.a`
  ${primaryButton({ size: 'sm', uppercase: true })}
`;

export const TrialParagraph = styled(MainParagraph)`
  color: ${medium};
  padding-left: 8px;
  margin-top: 16px;
  line-height: 22px;
  max-width: 140px;
  width: 100%;
`;
