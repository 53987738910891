import styled from 'styled-components';
import { backgroundPrimary } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const HeaderBar = styled.header`
  width: 100%;
  z-index: 50;
  position: relative;
  ${MEDIA.TABLET`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${backgroundPrimary};
    border-bottom: 1px solid rgba(62, 134, 145, 0.2);
    box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
 `};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 0;
  margin: 0 auto;
  .logo {
    max-width: 190px;
  }
  ${MEDIA.TABLET`
    padding: 16px 0;
    .logo {
      max-width: 48px;
      max-height: 48px;
    }
  `}
`;
