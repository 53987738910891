import React from 'react';
import PropTypes from 'prop-types';

import { AnchorWrapper, AnchorLink } from './anchor.css';

const Anchor = ({ className, id, children, ...rest }) => {
  return (
    <AnchorWrapper id={id} className={className} {...rest}>
      <AnchorLink to={`#${id}`} aria-label="Link to this section">
        §
      </AnchorLink>
      {children}
    </AnchorWrapper>
  );
};

Anchor.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Anchor;
