import styled from 'styled-components';
import { MainTitle, MainParagraph, Copy } from '../../../styles/typography.css';
import {
  light,
  dark,
  darkest,
  blogWidth,
  accentSecondary,
} from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import { BadgeWrapper } from '../category/badge.css';
import { primaryButton } from '../../../styles/buttons.css';

export const BlogContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  max-width: ${blogWidth};
  margin: 0 auto;
`;

export const BlogTitle = styled(MainTitle)`
  margin-bottom: 32px;
`;

export const MetaWrapper = styled.div`
  margin: 0 auto 16px;
  ${MEDIA.TABLET`
    margin-top: 76px;
 `};
  display: flex;
  justify-content: center;
`;

export const MetaCopy = styled(MainParagraph)`
  font-size: 18px;
  line-height: 28px;
  color: ${dark};
`;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  ${BadgeWrapper} {
    ${MEDIA.MIN_PHONE`
      &:first-of-type {
        font-size: 20px;
      }
    `}
    margin-bottom: 8px;
    ${MEDIA.PHONE`
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    `}
    ${MEDIA.MIN_PHONE`
      + ${BadgeWrapper} {
        margin-left: 8px;
      }
    `}
  }
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 0 16px 8px;
  ${MEDIA.TABLET`
    margin-left: 12px;
    margin-right: 12px;
  `}
  ${MEDIA.PHONE`
    display: none;
  `}
  background: ${light};
  border-radius: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  margin: 32px auto 0;
  box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.2);
  border-radius: 32px;
`;

export const BlogCopy = styled(Copy)`
  margin: 48px auto;
  .button-external {
    ${primaryButton({ size: 'lg' })}
    margin-top: 32px;
    margin-bottom: 32px;

    &.secondary {
      background: ${accentSecondary};
    }
  }
`;

export const SocialCopy = styled(MainParagraph)`
  font-size: 18px;
  line-height: 28px;
  color: ${darkest};
  font-weight: 700;
`;
