import { useRef } from 'react';

/**
 * @template T
 * @param {T} value
 * @returns {() => T}
 */
const useValueReference = (value) => {
  const ref = useRef();

  ref.current = value;

  return () => ref.current;
};

export default useValueReference;
