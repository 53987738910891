import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './nav.css';
import HeaderLinks from './links';

const HeaderNav = ({ links }) => (
  <Container>
    <ul>
      <HeaderLinks links={links} />
    </ul>
  </Container>
);

HeaderNav.propTypes = {
  links: PropTypes.array.isRequired,
};

export default HeaderNav;
