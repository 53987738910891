import styled from 'styled-components';
import { MainParagraph, SecondaryTitle } from '../../styles/typography.css';
import { sectionGap, sectionGapMobile } from '../../constants/theme';
import MEDIA from '../../helpers/mediaTemplates';

export const ExerciseWrapper = styled.section`
  overflow-x: hidden;
  padding-top: ${sectionGap};
  ${MEDIA.TABLET`
    padding-top: ${sectionGapMobile};
  `};
`;

export const ExerciseContent = styled.div`
  max-width: 540px;
  margin: auto;
`;

export const ExerciseTitle = styled(SecondaryTitle)`
  text-align: center;
  margin-bottom: 24px;
`;
export const ExerciseParagraph = styled(MainParagraph)`
  text-align: center;
  margin-bottom: 40px;
`;

export const ButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  ${MEDIA.TABLET`
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
  `};
`;
