import React from 'react';
import FreeTrialLayout from 'components/landings/freeTrial';

const freeTrial = () => (
  <FreeTrialLayout
    heading="you got this"
    subheading="30 Day Fitness subscription"
    closing="personalized workouts"
    hasqr
  />
);

export default freeTrial;
