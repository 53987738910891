import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Header from './header';
import OffCanvasNav from './offCanvasNav';
import { ScrollContainer, PerspectiveWrapper, Overlay } from './layout.css';

const Layout = ({ data, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const headerLinks = data.markdownRemark.frontmatter.menuItems;
  return (
    <>
      <PerspectiveWrapper isOpen={isOpen}>
        <ScrollContainer isOpen={isOpen} setIsOpen={setIsOpen}>
          <Header
            links={headerLinks}
            title={data.site.siteMetadata.siteTitle}
            setIsOpen={setIsOpen}
          />
          {children}
          <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
        </ScrollContainer>
        <OffCanvasNav links={headerLinks} isOpen={isOpen} />
      </PerspectiveWrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
        markdownRemark(
          fileAbsolutePath: { regex: "/settings/menu-items.md$/" }
        ) {
          frontmatter {
            menuItems {
              label
              path
            }
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
