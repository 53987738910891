import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '../structure/container';
import Section from '../structure/section';
import Head from '../common/head';
import Layout from '../layout/layout';
import FooterTile from '../common/footerTile';
import Footer from '../common/footer';
import { LegalCopy } from './layout.css';

export default class LegalLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    let post = markdownRemark.frontmatter;
    return (
      <Layout>
        <Head pageTitle={post.title} />
        <Section hasNoPadding>
          <Container>
            <LegalCopy
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html,
              }}
            />
          </Container>
        </Section>
        <FooterTile />
        <Footer />
      </Layout>
    );
  }
}

LegalLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query LegalQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
      }
    }
  }
`;
