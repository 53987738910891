import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Head from '../../common/head';
import Layout from '../../layout/layout';
import FooterTile from '../../common/footerTile';
import Footer from '../../common/footer';
import Container from '../../structure/container';
import Section from '../../structure/section';
import BlogHero from '../hero';
import Pager from '../pager';
import ArticlesList from '../articlesList';
import BeyondTeaser from './teaser';

function getLatestArticlesByCategory(articlesByCategory, excludeIds) {
  const latestArticlesByCategory = articlesByCategory.map((group) => {
    return group.edges.find((edge) => !excludeIds.includes(edge.node.id));
  });
  return latestArticlesByCategory;
}

const Beyond = ({ data, pageContext }) => {
  // First of the list
  const latestArticle = data.latestArticle.edges[0];
  // Latest by category. Exclude the latest article
  const latestArticlesByCategory = getLatestArticlesByCategory(
    data.articlesByCategory.group,
    [latestArticle.node.id]
  );

  let allArticles = data.allArticles.edges;

  // Only the first page features the latest articles
  const isFirstPage = !pageContext.skip;
  if (isFirstPage) {
    // Remove the latest article from the list
    allArticles = allArticles.slice(1);
    // Remove all the featured articles from the list
    allArticles = allArticles.filter(
      (edge) =>
        // [not some of condition] makes sure that every element is the opposite
        // of the condition without having to loop through all the elements.
        // Equivalent to [every of not condition]
        !latestArticlesByCategory.some(
          (latestArticle) => latestArticle.node.id === edge.node.id
        )
    );
  }

  return (
    <Layout>
      <Head pageTitleFull={data.aboutJson.title} />
      <Section hasNoPadding>
        <Container>
          <BlogHero
            featuredArticle={isFirstPage && latestArticle.node}
            campaign="beyond"
          >
            <BeyondTeaser {...data.aboutJson} />
          </BlogHero>
        </Container>
      </Section>
      {isFirstPage && (
        <Section isDarker>
          <Container>
            <ArticlesList
              title="Latest from our categories"
              articles={latestArticlesByCategory}
            />
          </Container>
        </Section>
      )}
      <Section hasNoPadding={!isFirstPage}>
        <Container>
          <ArticlesList
            title={isFirstPage ? 'More Articles' : ''}
            articles={allArticles}
          />
          <Pager {...pageContext} />
        </Container>
      </Section>
      <FooterTile campaign="beyond" />
      <Footer />
    </Layout>
  );
};

Beyond.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query BeyondQuery($skip: Int!, $limit: Int!) {
    aboutJson {
      ...BeyondTeaser
    }
    latestArticle: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      limit: 1
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          ...BlogCard
        }
      }
    }
    articlesByCategory: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      group(field: frontmatter___categories, limit: 2) {
        edges {
          node {
            id
            ...BlogCard
          }
        }
      }
    }
    allArticles: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          ...BlogCard
        }
      }
    }
  }
`;

export default Beyond;
