import styled from 'styled-components';
import { accentSecondary, white } from '../../constants/theme';
import { MainTitle, MainParagraph } from '../../styles/typography.css';
import MEDIA from '../../helpers/mediaTemplates';

export const HeroWrapper = styled.div`
  height: 100vh;
  min-height: 900px;
  max-height: 1024px;
  display: flex;
  margin: -120px auto 0;
  position: relative;
  width: auto;
  ${MEDIA.TABLET`
    max-width: 540px;
    min-height: auto;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    padding-top: 140px;
 `};
`;

export const HeroContent = styled.div`
  margin: auto;
  flex: 1;
`;

export const HeroTitle = styled(MainTitle)``;

export const HeroParagraph = styled(MainParagraph)``;

export const HeroArtwork = styled.div`
  position: relative;
  flex: 1;
  ${MEDIA.TABLET`
    display: none;
 `};
`;

export const ArtworkWrapper = styled.div`
  position: absolute;
  bottom: 200px;
  left: 10%;
  width: 400px;
  ${MEDIA.DESKTOP`
    width: 360px;
 `};
`;

export const BarWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  display: flex;
  bottom: 112px;
  left: 0;
  align-items: center;
  width: 100%;
  padding: 24px 40px;
  background: #ffffff;
  border: 0.5px solid rgba(62, 134, 145, 0.216429);
  box-shadow: 0px 4px 32px rgba(62, 62, 62, 0.08);
  border-radius: 16px;
  max-width: 1280px !important;
  ${MEDIA.TABLET`
    position: initial;
    padding: 48px 0 64px;
    flex-direction: column;
    border: none;
    background: transparent;
    box-shadow: none;
 `}
`;

export const AppOfTheDay = styled.div`
  max-width: 140px;
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid #eaeaea;
  ${MEDIA.TABLET`
    margin: 0 0 24px;
    padding: 0 0 24px;
    border-right: none;
    border-bottom: 1px solid #eaeaea;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  ${MEDIA.TABLET`
    max-width: 335px;
    justify-content: space-between;
    width: 100%;
  `}
`;

export const HighilightWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: ${accentSecondary};
  padding: 0 64px;
  display: flex;
  ${MEDIA.WIDESCREEN`
    padding: 0 32px;
 `}
  ${MEDIA.DESKTOP`
    display: none;
 `}
`;

export const HighilightText = styled(MainParagraph)`
  color: ${white};
  margin: auto;
  font-weight: 600;
`;
