import styled from 'styled-components';
import { accentPrimary, dark } from '../../../constants/theme';

export const LinkWrapper = styled.li`
  a {
    transition: color 0.2s ease;
    color: ${({ isActive }) => (isActive ? accentPrimary : dark)};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${accentPrimary};
    }
  }
`;
